import React, { useState } from 'react';

import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { camelCaseToUserText } from '@luxon/utils';

import { useFormV2Context } from './Form.context';
import { Button } from '../Button';

interface IFormTextInputProps<T> extends Omit<TextFieldProps, 'label' | 'type' | 'name' | 'disabled' | 'value' | 'onChange' | 'InputProps' | 'error'> {
    label?: string;
    type?: 'text' | 'number' | 'email' | 'tel' | 'password' | 'color';
    name: keyof T;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    maxLength?: number;
}
export function FormTextInput<T>(props: IFormTextInputProps<T>) {
    const {
        label,
        type,
        name,
        disabled,
        startIcon,
        endIcon,
        maxLength,
        ...baseInputProps
    } = props;

    const [hasFocus, setHasFocus] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors
    } = useFormV2Context<T>();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        handleFormChange(name, newValue);
    };

    return (
        <TextField
            {...baseInputProps}
            label={label ?? camelCaseToUserText(String(name))}
            variant='outlined'
            name={String(name)}
            type={(type === 'password' && passwordVisible ? 'text' : type) ?? 'text'}
            value={formData[name]}
            InputLabelProps={{
                shrink: !!formData[name] || hasFocus
            }}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            disabled={disabled || formDisabled}
            error={formErrors[name]}
            InputProps={{
                startAdornment: !startIcon ? null : (
                    <InputAdornment position='start'>
                        {startIcon}
                    </InputAdornment>
                ),
                endAdornment: !endIcon ? (type === 'password' ? (
                    <Button
                        iconOnly
                        size='small'
                        icon={passwordVisible ? <Visibility /> : <VisibilityOff />}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                ) : null) : (
                    <InputAdornment position='end'>
                        {endIcon}
                    </InputAdornment>
                ),
                inputProps: {
                    maxLength: maxLength,
                    onInput: handleInputChange
                }
            }}
        />
    )
};