import { FunctionComponent } from 'react';
import { useAppSettings } from '@luxon/hooks';
import { FeatureFlag } from '@luxon/models';

interface IFeatureGuardProps {
    featureName: FeatureFlag;
    fallbackComponent: React.ReactNode;
    children: any;
}
export const FeatureGuard: FunctionComponent<IFeatureGuardProps> = (props: IFeatureGuardProps) => {

    const { featureName, fallbackComponent, children } = props;

    const { isFeatureEnabled } = useAppSettings();

    if (!isFeatureEnabled(featureName)) {
        return (
            <>
                {fallbackComponent}
            </>
        );
    }

    return (
        <>
            {children}
        </>
    );
};