import { FunctionComponent } from 'react';

import { Menu, MenuItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import { IMenuItem } from '@luxon/interfaces';

interface IContextMenuProps {
  options: IMenuItem[];
  onOptionSelected: (option: IMenuItem) => void;
  onClose: VoidFunction;
  anchorElement?: HTMLElement;
  hideDisabledItems?: boolean;
}
export const ContextMenu: FunctionComponent<IContextMenuProps> = (props: IContextMenuProps) => {

  const {
    options,
    onOptionSelected,
    anchorElement,
    onClose,
    hideDisabledItems
  } = props;

  const handleOptionSelected = (option: IMenuItem) => {
    onOptionSelected(option);
    onClose();
  }

  return (
    <Menu
      open={!!anchorElement}
      anchorEl={anchorElement}
      onClose={onClose}
      sx={{maxHeight: '1000px'}}
    >
      {
        options.filter(x => !hideDisabledItems || !x.disabled).map((x, index) => x.isDivider ? (
          <Divider key={index} />
        ) : (
          <MenuItem
            key={index}
            onClick={() => handleOptionSelected(x)}
            disabled={x.disabled}
          >
            <ListItemIcon>
                {x.icon}
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant='body2' maxWidth='200px' whiteSpace='normal'>
                  {x.text}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))
      }
    </Menu>
  )
};