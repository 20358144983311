import React, { FunctionComponent } from 'react';

import { FormControlLabel, Checkbox as MuiCheckbox, Typography } from '@mui/material';

import { IFormInputBase } from '@luxon/components/core/form/Form';

interface ICheckboxProps extends IFormInputBase {
    indeterminate?: boolean;
    size?: 'small' | 'medium';
}
export const Checkbox: FunctionComponent<ICheckboxProps> = (props: ICheckboxProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange && !props.readOnly) {
            props.onChange(e.target.checked);
        }
    };

    return (
        <FormControlLabel
            label={props.label ? <Typography variant={props.size === 'small' ? 'body2' : 'body1'}>{props.label}</Typography> : null}
            sx={{
                marginRight: props.label ? null : '0px',
                color: props.disabled ? 'var(--color-text-disabled)' : ''
            }}
            control={
                <MuiCheckbox
                    onChange={handleChange}
                    readOnly={props.readOnly}
                    size={props.size}
                    checked={props.value}
                    indeterminate={props.indeterminate} />
            }
            name={props.name}
            disabled={props.disabled}
        />
    )
};