import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { IFormInput, useFormContext } from '@luxon/components';

import { Unstable_Grid2 as Grid, SxProps, Divider } from '@mui/material';
import { Theme } from '@mui/system';

import { parseFormInputName } from '@luxon/formatters';

interface IInputGridWrapperProps {
    formInput: IFormInput;
    uniqueName: string;
    alignItems?: string;
    justifyContent?: string;
    display?: string;
    sx?: SxProps<Theme>
    children: any;
}
const InputGridWrapper: FunctionComponent<IInputGridWrapperProps> = (props: IInputGridWrapperProps) => {
    const getGridSize = (formInput: IFormInput, size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): number => {
        if (!formInput.gridWidth || typeof formInput.gridWidth === 'number') {
            return formInput.gridWidth as number ?? 12;
        }

        return (formInput.gridWidth as any)[size];
    };

    return (
        <Grid
            key={props.uniqueName}
            xs={getGridSize(props.formInput, 'xs')}
            sm={getGridSize(props.formInput, 'sm')}
            md={getGridSize(props.formInput, 'md')}
            lg={getGridSize(props.formInput, 'lg')}
            xl={getGridSize(props.formInput, 'xl')}
            alignItems={props.alignItems}
            justifyContent={props.justifyContent}
            display={props.display}
            sx={props.sx}
        >
            {props.children}
        </Grid>
    );
};

interface IFormInputWrapperProps {
    formInput: IFormInput;
    uniqueName: string;
    onChange: (newVal: string) => void;
    value: any;
    error: boolean;
}
const FormInputWrapper: FunctionComponent<IFormInputWrapperProps> = (props: IFormInputWrapperProps) => {
    const formContext = useFormContext();
    const [inputValue, setInputValue] = useState<any>();

    useEffect(() => {
        if (props.value === inputValue) {
            return;
        }
        setInputValue(props.value ?? null);
    }, [props.value, inputValue]);

    if (props.formInput.controlType === 'custom') {
        return props.formInput.component;
    } else if (inputValue === undefined && props.formInput.controlType !== 'button') {
        return null;
    }

    return React.cloneElement(props.formInput.component, {
        label: props.formInput.label,
        name: props.uniqueName,
        onChange: props.onChange,
        onClick: (e: React.MouseEvent<HTMLElement>) => props.formInput.onClick(e, props.uniqueName),
        value: inputValue,
        disabled: props.formInput.disabled || formContext.disabled,
        readOnly: props.formInput.readOnly || formContext.readOnly,
        required: props.formInput.required,
        error: props.error || props.formInput.error
    });
}

interface IFormInputOutletProps {
    inputNamesToRender?: string[]
}
export const FormInputsOutlet: FunctionComponent<IFormInputOutletProps> = (props: IFormInputOutletProps) => {
    const formContext = useFormContext();

    const inputsToRender: IFormInput[] = useMemo(() => {
        return formContext.inputs
            .filter(x => x.shouldRender !== false)
            .filter(x => !props.inputNamesToRender || props.inputNamesToRender.findIndex(y => y === x.name) >= 0);
    }, [formContext.inputs, props.inputNamesToRender]);

    if (!formContext.inputs || formContext.inputs.length < 1) {
        return null;
    }

    const getInputUniqueName = (formInput: IFormInput, index: number = null) =>
        `${(formInput.name ?? formInput.label)}${index !== null && index >= 0 ? (`[${index}]`) : ''}`;

    const getNestedInputUniqueName = (parentName: string, formInput: IFormInput, inputIndex: number = null) =>
        `${parentName}.${getInputUniqueName(formInput, inputIndex)}`;

    const renderComponent = (formInput: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, formInput)
            : getInputUniqueName(formInput);

        const onChangeOverride = (newVal: any) => {
            formContext.inputChanged({ value: newVal, name: uniqueName });

            if (formInput.onChange && typeof formInput.onChange === 'function') {
                formInput.onChange(newVal, uniqueName);
            }
        }

        let inputValue = formContext.formData ? Object.assign(formContext.formData) : undefined;
        if (formContext.formData) {
            const nameParts = uniqueName.split('.');
            for (const part of nameParts) {
                const { itemName, itemIndex } = parseFormInputName(part);

                if (!itemName) {
                    continue;
                }

                if (itemIndex === null) {
                    inputValue = inputValue[itemName];
                } else {
                    inputValue = inputValue[itemName][itemIndex];
                }

                if (inputValue === null || inputValue === undefined) {
                    break;
                }
            }
        }

        const isError = formContext.invalidInputs.indexOf(uniqueName) > -1;

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                formInput={formInput}
                display={formInput.controlType === 'button' ? 'flex' : null}
                justifyContent={formInput.controlType === 'button' ? 'center' : null}
                alignItems={formInput.controlType === 'button' ? 'center' : null}
            >
                <FormInputWrapper
                    formInput={formInput}
                    uniqueName={uniqueName}
                    value={inputValue}
                    onChange={onChangeOverride}
                    error={isError}
                />
            </InputGridWrapper>
        );
    }

    const buildNestedInputs = (formInput: IFormInput, parentUniqueName: string): JSX.Element[] => {
        const nestedInputUniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, formInput)
            : getInputUniqueName(formInput);

        let childrenData = Object.assign({}, formContext.formData ?? {});
        const parentUniqueNameParts = nestedInputUniqueName.split('.');
        for (const part of parentUniqueNameParts) {
            const { itemName, itemIndex } = parseFormInputName(part);

            if (!itemName) {
                continue;
            }

            if (itemIndex === null) {
                childrenData = childrenData[itemName];
            } else {
                childrenData = childrenData[itemIndex];
            }

            if (childrenData === null || childrenData === undefined) {
                break;
            }
        }

        return (childrenData ?? []).map((_: any, index: number) => {
            const uniqueName = parentUniqueName
                ? getNestedInputUniqueName(parentUniqueName, formInput, index)
                : getInputUniqueName(formInput, index);

            const components = formInput.children.map((childInputProps) => 
                getInput(childInputProps, uniqueName)
            ).flatMap(x => x);

            if (index < childrenData.length - 1) {
                components.push(
                    <Grid key={`${uniqueName}_divider`} xs={12} sx={{margin: '20px 0px'}}>
                        <Divider />
                    </Grid>
                );
            }
            return components;
        }).flatMap((x: JSX.Element[]) => x);
    };

    const getInput = (formInput: IFormInput, parentUniqueName: string = null): JSX.Element | JSX.Element[] => {
        switch (formInput.controlType) {
            case 'nested': return buildNestedInputs(formInput, parentUniqueName);
            default: return renderComponent(formInput, parentUniqueName);
        }
    }

    return (
        <Grid container rowSpacing={0} columnSpacing={2} flexDirection='row'>
            {
                inputsToRender.map(formInput => getInput(formInput))
            }
        </Grid>
    )
};