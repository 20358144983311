import React, { FunctionComponent, useState } from 'react';

import { IInputProps, Input } from '@luxon/components';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Password: FunctionComponent<IInputProps> = (props: IInputProps) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <Input {...props}
            type={passwordVisible ? 'text' : 'password'}
            endIcon={passwordVisible ? <Visibility /> : <VisibilityOff />}
            endIconIsButton
            endIconButtonClicked={() => setPasswordVisible(!passwordVisible)}
        />
    )
}

export default Password;