import React, { FunctionComponent } from 'react';

import { TableBody, TableRow, TableCell, Typography } from '@mui/material';

interface INoDataRowProps {
    columnCount: number;
    noDataTextOverride?: string;
}
const NoDataRow: FunctionComponent<INoDataRowProps> = (props: INoDataRowProps) => {

    return (
        <TableBody>
            <TableRow>
                <TableCell
                    colSpan={props.columnCount}
                    align='center'
                    style={{
                        borderBottom: 'none',
                        padding: '50px 0px'
                    }}>
                    <Typography>
                        <i>
                            {props.noDataTextOverride ?? 'No data available. Please check if you have any filters applied...'}
                        </i>
                    </Typography>
                </TableCell>
            </TableRow>
        </TableBody>
    )
};

export default NoDataRow;