const CACHE_KEY_NAME = 'ENV_SETTINGS_V2';

const getEnvironmentSettings = (): { apiBaseUrl: string } => {
    const sessionStorageResult = sessionStorage.getItem(CACHE_KEY_NAME);
    return (sessionStorageResult ? JSON.parse(sessionStorageResult) : null) as { apiBaseUrl: string };
};

const setEnvironmentSettings = (settings: { apiBaseUrl: string }) => {
    sessionStorage.setItem(CACHE_KEY_NAME, JSON.stringify(settings));
}

const environmentServiceWrapper = {
    getEnvironmentSettings,
    setEnvironmentSettings
};

export default environmentServiceWrapper;