import { FunctionComponent } from 'react';
import './metrics-square.scss';

import { Box, Typography } from '@mui/material';

import { ProgressSpinner } from '@luxon/components';
import { useScreenSize } from '@luxon/hooks';
import { formatNumber } from '@luxon/formatters';

export type TMetricBlockImage = 'truck' | 'car' | 'taxi' | 'motorbike' | 'pedestrian';

interface IMetricsSquareProps {
    value: number;
    description: string;
    imageName?: TMetricBlockImage;
    isLoading?: boolean;
    size?: 'small' | 'normal';
}
const MetricsSquare: FunctionComponent<IMetricsSquareProps> = (props: IMetricsSquareProps) => {
    const { isMobile, isSmallDesktop } = useScreenSize();

    const classNames = ['metrics-square'];
    const hasImage = props.imageName && !isMobile;

    let imagePath = '';
    if (hasImage) {
        imagePath = `/metric-icons/${props.imageName}.webp`;
        classNames.push('has-image');
    }

    return (
        <Box className={classNames.join(' ')}>
            {
                props.isLoading ? (
                    <ProgressSpinner color='light' size={41} />
                ) : (
                    <Typography
                        variant={isMobile || isSmallDesktop ? 'body3' : props.size === 'small' ? 'h6' : 'h4'}
                        fontWeight={500}
                        color='light'
                        className='no-overflow'
                    >
                        {formatNumber(props.value)}
                    </Typography>
                )
            }

            <Typography
                variant={(hasImage || isMobile || isSmallDesktop) ? 'body3' : 'body2'}
                color='light'
                className='no-overflow'
                display='flex'
                flexDirection='column'
                alignItems='center'
                rowGap='3px'
            >
                {
                    hasImage && (
                        <img src={imagePath} alt={props.imageName} height={isSmallDesktop ? '15px' : '23px'} />
                    )
                }

                {props.description}
            </Typography>
        </Box>
    )
};

export default MetricsSquare;