import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import "./geo-map.scss";

import { Stack } from "@mui/material";

import { GoogleMapsLibraryLoader, ProgressSpinner } from "@luxon/components";
import { IGeoPoint } from "@luxon/interfaces";
import { createResizeObserver } from "@luxon/utils";

import GeoMarker, { IGeoMarker } from "./geo-marker";
import GeoPolygon from "./geo-polygon";

// Defaults to the center of Midrand, Gauteng, ZA
export const DEFAULT_MAP_CENTER: IGeoPoint = {
  latitude: -26.19112715496492,
  longitude: 28.02767024340821,
};

export type TGeoMapMode = "ASSET" | "ASSET_FENCE" | "COVERAGE";

export interface IGeoMapRef {
  moveTo: (point: IGeoPoint, zoom?: number) => void;
  setZoom: (zoom: number) => void;
}

interface IGeoMapProps {
  mode: TGeoMapMode;
  editable?: boolean;

  polygonGeoPoints?: IGeoPoint[];
  polygons?: IGeoPoint[][];
  polygonGeoPointsChanged?: (points: IGeoPoint[]) => void;

  markers?: IGeoMarker[];
  markerAdded?: (position: IGeoPoint) => void;
  markerPositionChanged?: (marker: IGeoMarker, newPosition: IGeoPoint) => void;
  markerClicked?: (marker: IGeoMarker, event: MouseEvent) => void;
  placeMarkersInView?: boolean;
  alwaysShowMarkerLabels?: boolean;

  boundsChanged?: (topRight: IGeoPoint, bottomLeft: IGeoPoint) => void;
  zoomChanged?: (zoom: number) => void;
  onMapIdle?: () => void;
  heightRatio?: number;
  fullHeight?: boolean;
  zoom?: number;
  center?: IGeoPoint;
  hideControls?: boolean;
  wrapperAutoHeight?: boolean;

  mapRef?: React.MutableRefObject<IGeoMapRef>;
}

const Map: FunctionComponent<IGeoMapProps> = (props: IGeoMapProps) => {
  const mapElRef = useRef<HTMLDivElement>();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const mapIdleTimeout = useRef<NodeJS.Timeout>(null);
  const [mapZoom, setMapZoom] = useState(8);

  const {
    boundsChanged,
    markerAdded,
    polygonGeoPointsChanged,
    onMapIdle,
    placeMarkersInView,
    markers,
  } = props;

  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
      if (!props.editable) {
        return;
      }

      const geoPoint: IGeoPoint = {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      };

      if (props.mode === "ASSET") {
        markerAdded(geoPoint);
      } else if (props.mode === "ASSET_FENCE") {
        polygonGeoPointsChanged([...props.polygonGeoPoints, geoPoint]);
      }
    },
    [props.editable, props.mode, props.polygonGeoPoints, markerAdded, polygonGeoPointsChanged]
  );

  const mapIdle = useCallback(() => {
    if (mapIdleTimeout.current) {
      clearTimeout(mapIdleTimeout.current);
    }

    mapIdleTimeout.current = setTimeout(() => {
      if (boundsChanged) {
        const mapBounds = map.getBounds();
        const topRight = mapBounds.getNorthEast();
        const bottomLeft = mapBounds.getSouthWest();
        boundsChanged(
          { latitude: topRight.lat(), longitude: topRight.lng() },
          { latitude: bottomLeft.lat(), longitude: bottomLeft.lng() }
        );
      }

      if (!mapInitialized && placeMarkersInView && markers?.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        for (const marker of markers) {
          bounds.extend({
            lat: marker.position.latitude,
            lng: marker.position.longitude,
          });
        }
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        map.setZoom(map.getZoom() - 1);
      }

      if (!mapInitialized) {
        setMapInitialized(true);
      }

      if (onMapIdle) {
        onMapIdle();
      }
    }, 200);
  }, [boundsChanged, map, placeMarkersInView, markers, mapInitialized, onMapIdle]);

  useEffect(() => {
    if (!mapElRef.current || props.fullHeight) {
      // Reset height incase it was previously set
      if (
        props.fullHeight &&
        mapElRef.current &&
        mapElRef.current.style.height !== ""
      ) {
        mapElRef.current.style.height = "";
      }
      return;
    }

    const setMapHeight = () => {
      if (!mapElRef.current) {
        return;
      }

      const newHeight = mapElRef.current.clientWidth * (props.heightRatio ?? 0.6);
      mapElRef.current.style.height = `${newHeight}px`;
    };
    const resizeObserver = createResizeObserver(() => {
      setMapHeight();
    });
    resizeObserver.observe(mapElRef.current);
    setMapHeight();

    return () => {
      resizeObserver.disconnect();
    };
  }, [mapElRef, props.heightRatio, props.fullHeight]);

  useEffect(() => {
    if (!mapElRef.current || map) {
      return;
    }

    setMap(
      new google.maps.Map(mapElRef.current, {
        zoom: mapZoom,
        center: {
          lat: DEFAULT_MAP_CENTER.latitude,
          lng: DEFAULT_MAP_CENTER.longitude,
        },
        clickableIcons: false,
        disableDefaultUI: props.hideControls,
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
        ],
      })
    );
  }, [mapElRef, map, props.hideControls, mapZoom]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (props.editable) {
        map.addListener("click", onMapClick);
      }

      map.addListener("idle", mapIdle);
      map.addListener("zoom_changed", () => {
        setMapZoom(map.getZoom());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, props.editable, onMapClick, props.boundsChanged, mapIdle]);

  useEffect(() => {
    if (!map || !props.zoom) {
      return;
    }

    if (props.zoom !== map.getZoom()) {
      map.setZoom(props.zoom);
    }
  }, [map, props.zoom]);

  useEffect(() => {
    if (!map || !props.center) {
      return;
    }

    const currentCenter = map.getCenter();
    if (
      props.center.latitude !== currentCenter.lat() ||
      props.center.longitude !== currentCenter.lng()
    ) {
      map.panTo({
        lat: props.center.latitude,
        lng: props.center.longitude,
      });
    }
  }, [map, props.center]);

  useEffect(() => {
    setMapInitialized(false);
  }, [markers]);

  useEffect(() => {
    if (!props.mapRef || !map) {
      return;
    }

    props.mapRef.current = {
      moveTo: (point: IGeoPoint, zoom?: number) => {
        map.panTo({
          lat: point.latitude,
          lng: point.longitude
        });
        if (zoom && zoom !== map.getZoom()) {
          map.setZoom(zoom);
          setMapZoom(zoom);
        }
      },
      setZoom: (zoom: number) => {
        if (zoom !== map.getZoom()) {
          map.setZoom(zoom);
        }
        setMapZoom(zoom);
      }
    }
  }, [props.mapRef, map]);

  useEffect(() => {
    if (props.zoomChanged) {
      props.zoomChanged(mapZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapZoom]);

  return (
    <>
      <div ref={mapElRef} className="geo-map"></div>
      {props.markers?.length > 0 &&
        props.markers.map((marker, i) => (
          <GeoMarker
            key={marker.key ?? i}
            map={map}
            marker={marker}
            labelEnabled={
              props.alwaysShowMarkerLabels ||
              (mapZoom >= 8 && props.markers.length < 50)
            }
            positionChanged={(point) =>
              props.markerPositionChanged
                ? props.markerPositionChanged(marker, point)
                : null
            }
            onMarkerClick={(e: MouseEvent) =>
              props.markerClicked ? props.markerClicked(marker, e) : null
            }
          />
        ))}
      {props.mode === "ASSET_FENCE" && (
        <GeoPolygon
          map={map}
          points={props.polygonGeoPoints}
          editable={props.editable}
          pointsChanged={props.polygonGeoPointsChanged}
        />
      )}
      {props.polygons?.length > 0 &&
        props.polygons.map((points, i) => (
          <GeoPolygon key={i} map={map} points={points} editable={false} />
        ))}
    </>
  );
};

const GeoMap: FunctionComponent<IGeoMapProps> = (props: IGeoMapProps) => {
  const render = (status: string) => {
    return (
      <Stack display="flex" alignItems="center" sx={{ margin: "30px 0" }} data-status={status}>
        <ProgressSpinner />
      </Stack>
    );
  };

  return (
    <div
      className={`geo-map-wrapper ${
        props.wrapperAutoHeight ? "auto-height" : "full-height"
      }`}
    >
      <GoogleMapsLibraryLoader
        loadingRender={render}
      >
        <Map {...props} />  
      </GoogleMapsLibraryLoader>
    </div>
  );
};

export default GeoMap;
