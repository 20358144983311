import { useQuery, useMutation, useQueryClient } from "react-query";

import { IClient, ICreateClientRequest, IDefaultResponseMessage, IGetClientRequest, IPaginationWrapper, ISubscriptionFee, IUpdateClientRequest, TStatus, TSuspendedReason } from '@luxon/interfaces';
import { HttpClientError, QueryKeys, getQuerySettings, IQuerySettings } from '@luxon/models';
import { useHttpClient, useSnackbar } from '@luxon/hooks';
import { useUserContext } from "@luxon/providers";

enum ClientQueryKey {
  SubscriptionFee = 'subscription-fee',
  List = 'List'
}

const BASE_URL = '/v1/clients';

export const useGetClient = (clientId: string, settings?: IQuerySettings<IClient>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IClient, HttpClientError>([QueryKeys.Clients, clientId], () => {
        return GET<IClient>(`${BASE_URL}/${clientId}`, null, {
          autoShowErrorMessages: querySettings.autoShowErrorMessages && !querySettings.refetchInterval,
          autoLogOutOnAuthError: querySettings.autoLogOutOnAuthError
        });
    }, {
      enabled: querySettings.enabled && !!clientId,
      onSuccess: querySettings.onSuccess,
      onError: querySettings.onError,
      staleTime: querySettings.staleTime,
    });
}

export const useGetClients = (request: IGetClientRequest, settings?: IQuerySettings<IPaginationWrapper<IClient>>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IPaginationWrapper<IClient>, HttpClientError>([QueryKeys.Clients, ClientQueryKey.List, request], () => {
      return GET<IPaginationWrapper<IClient>>(BASE_URL, request);
  }, {
    enabled: querySettings.enabled,
    onSuccess: querySettings.onSuccess,
    onError: querySettings.onError,
    staleTime: querySettings.staleTime,
  });
}

export const useGetClientSubscriptionFee = (clientId: string, settings?: IQuerySettings<ISubscriptionFee>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<ISubscriptionFee, HttpClientError>([QueryKeys.Clients, ClientQueryKey.SubscriptionFee, clientId], () => {
        return GET<ISubscriptionFee>(`${BASE_URL}/${clientId}/subscription-fee`);
    }, {
      enabled: querySettings.enabled && !!clientId,
      onSuccess: querySettings.onSuccess,
      onError: querySettings.onError,
      staleTime: querySettings.staleTime,
    });
}

export const useGetClientSubscriptionAgreement = (settings?: IQuerySettings<IDefaultResponseMessage, { clientId: string }>) => {
  const { GET } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const getClientSubscriptionAgreement = ({ clientId }: { clientId: string }) => {
    return GET<IDefaultResponseMessage>(`${BASE_URL}/${clientId}/subscription-agreement`);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, { clientId: string }>(getClientSubscriptionAgreement, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

export const useUpdateSubscriptionAgreement = (settings?: IQuerySettings<IClient, { clientId: string, file: File }>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateSubscriptionAgreement = ({ clientId, file }: { clientId: string, file: File }) => {
    return POST<IClient>(`${BASE_URL}/${clientId}/subscription-agreement`, null, file);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, { clientId: string, file: File }>(updateSubscriptionAgreement, {
    onSuccess: (response, input) => {
      showSnackbar('Subscription agreement successfully updated.');
      queryClient.setQueryData([QueryKeys.Clients, input.clientId], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

export const useCreateClient = (settings?: IQuerySettings<IClient, ICreateClientRequest>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const createClient = (request: ICreateClientRequest) => {
    return POST<IClient>(BASE_URL, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, ICreateClientRequest>(createClient, {
    onSuccess: (response, input) => {
      showSnackbar('Client successfully created.');
      queryClient.setQueryData([QueryKeys.Clients, response.id], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

interface UpdateClientRequest {
  clientId: string;
  request: IUpdateClientRequest;
  finishOnboarding?: boolean;
  newLogo?: File;
}
export const useUpdateClient = (settings?: IQuerySettings<IClient, UpdateClientRequest>) => {
  const { POST, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const updateClient = ({ clientId, request, newLogo, finishOnboarding }: UpdateClientRequest) => {
    return POST<IClient>(buildUrl(`${BASE_URL}/${clientId}`, { finishOnboarding: finishOnboarding ?? false }), request, newLogo, {
      bodyAsForm: true
    });
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, UpdateClientRequest>(updateClient, {
    onSuccess: (response, input) => {
      showSnackbar(`${user.isSystemsUser ? 'Client' : 'Account'} successfully updated.`);
      queryClient.setQueryData([QueryKeys.Clients, response.id], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

interface UpdateClientStatusRequest {
  clientId: string;
  status: TStatus;
  suspendedReason: TSuspendedReason;
}
export const useUpdateClientStatus = (settings?: IQuerySettings<IClient, UpdateClientStatusRequest>) => {
  const { PUT, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateClientStatus = ({ clientId, status, suspendedReason }: UpdateClientStatusRequest) => {
    return PUT<IClient>(buildUrl(`${BASE_URL}/${clientId}/status`, { status, suspendedReason }));
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, UpdateClientStatusRequest>(updateClientStatus, {
    onSuccess: (response, input) => {
      showSnackbar(`Client successfully ${response.status === 'Suspended' ? 'suspended' : 'activated'}`);
      queryClient.setQueryData([QueryKeys.Clients, response.id], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}