import React, { FunctionComponent } from 'react';

import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { IFormInputBase } from '../core/form/Form';

interface ISwitchProps extends IFormInputBase {}

const Switch: FunctionComponent<ISwitchProps> = (props: ISwitchProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e.target.checked);
        }
    };

    return (
        <FormControlLabel
            label={props.label}
            control={
                <MuiSwitch onChange={handleChange} checked={props.value} />
            }
            name={props.name}
            disabled={props.disabled || props.readOnly}
        />
    )
};

export default Switch;