import { FunctionComponent } from 'react';

import { Navigate, useSearchParams } from 'react-router-dom';

import { useUserContext, useClientContext } from '@luxon/providers';
import { IClient, IUser } from '@luxon/interfaces';

export const calculateHomePage = (user: IUser, client: IClient): string => {
    if (user.isSystemsUser) {
        return '/dashboard/clients';
    } else if (client.status === 'Onboarding') {
        return '/dashboard/onboarding';
    } else if (client.status === 'Suspended' && client.suspendedReason === 'NoPayment') {
        return '/dashboard/invoices'
    }

    return '/dashboard/analytics';
}

interface IRedirectLoggedInUserProps {}
const RedirectLoggedInUser: FunctionComponent<IRedirectLoggedInUserProps> = (props: IRedirectLoggedInUserProps) => {
    const [searchParams] = useSearchParams();

    const userContext = useUserContext();
    const clientContext = useClientContext();

    if (searchParams.get('r')) {
        return <Navigate to={searchParams.get('r')} />;
    } 
    
    const homePageUrl = calculateHomePage(userContext.user, clientContext.client);
    return <Navigate to={homePageUrl} />
};

export default RedirectLoggedInUser;