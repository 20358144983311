import { FunctionComponent } from 'react';

import { TColor } from '@luxon/interfaces';

import { RadarChart as RechartsRadarChart, Radar as RechartsRadar, PolarGrid as RechartsPolarGrid, PolarAngleAxis as RechartsPolarAngleAxis, PolarRadiusAxis as RechartsPolarRadiusAxis } from 'recharts';

import { IGenericChartProps } from '.';

const RadarChart: FunctionComponent<IGenericChartProps> = (props: IGenericChartProps) => {
    const radarData: Array<{ label: string, value: any, color: TColor, key: string }> = props.dataKeys
        .filter(x => props.disabledKeys.indexOf(x.dataKey) < 0)
        .map(x => ({
            label: x.legendName,
            value: props.data.map(y => y[x.dataKey]).reduce((a, b) => a + b),
            color: x.color,
            key: x.dataKey
        }));

    return (
        <RechartsRadarChart
            width={props.width}
            height={props.height}
            cx='50%'
            cy='50%'
            data={radarData}
            className='chart'
        >
            {props.children}
            <RechartsPolarGrid />
            <RechartsPolarAngleAxis dataKey='label' />
            <RechartsPolarRadiusAxis />
            <RechartsRadar
                dataKey='value'
                stroke={`var(--color-${props.chartColor ?? 'primary'})`}
                fill={`var(--color-${props.chartColor ?? 'primary'})`}
                fillOpacity={0.7}
                animationDuration={500}
                animationBegin={0}
            />
        </RechartsRadarChart>
    )
};

export default RadarChart;