import React, { FunctionComponent, useMemo, useState } from 'react';
import './site-header.scss';

import { Link as RouterLink } from 'react-router-dom';

import { Person, Menu as MenuIcon, Link, AssignmentInd } from '@mui/icons-material';

import { Menu, MenuItem, ListItemIcon, ListItemText, Divider, Typography, Stack } from '@mui/material';

import { Button, StatusChip } from '@luxon/components';
import { useClientContext, useUserContext } from '@luxon/providers';
import { IMenuItem } from '@luxon/interfaces';
import { useScreenSize, useSnackbar, useHttpClient } from '@luxon/hooks';

import luxonLogo from '@luxon/images/luxon-logo.png';

interface ISiteHeaderProps {
    menuItems: IMenuItem[];
    homePageLink: string;
}
const SiteHeader: FunctionComponent<ISiteHeaderProps> = (props: ISiteHeaderProps) => {
    const { user } = useUserContext();
    const { client } = useClientContext();
    const { isMobile } = useScreenSize();
    const { buildUrl } = useHttpClient();
    const { showSnackbar } = useSnackbar();

    const clientLogoUrl = useMemo(() => {
        if (!client || !client.hasLogo || !client.auditTimeStamp.toDate) {
            return '';
        }


        return buildUrl(`/v1/clients/${client.id}/logo?v=${client.auditTimeStamp.toDate().getTime()}`);
    }, [client, buildUrl]);
    const menuItemToUse = useMemo(() => {
        const newMenuItems = [...props.menuItems];
        if (user.role === 'SystemsOwner') {
            const supportIndex = newMenuItems.findIndex(x => x.text === 'Support');
            newMenuItems.splice(supportIndex - 1, 0, ...([
                { isDivider: true },
                { text: 'Xero Setup', icon: <Link />, value: '/xero-setup' }
            ] as IMenuItem[]));
        };
        return newMenuItems;
    }, [props.menuItems, user]);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
    const userMenuOpen = Boolean(userMenuAnchorEl);

    const copyAccountNumber = () => {
        navigator.clipboard.writeText(client.accountNumber);
        showSnackbar('Account Number copied to clipboard');
    };

    return (
        <div className='site-header'>
            <RouterLink to={props.homePageLink}>
                <img src={luxonLogo} className='luxon-logo' alt='Luxon Logo' width={208} height={40} />
            </RouterLink>
            <Stack direction='row' flex={1} justifyContent='flex-end' alignItems='center' columnGap={3}>
                {
                    client.trialModeEnabled && (
                        <RouterLink to='/dashboard/account' className='link'>
                            <StatusChip status='TrialMode' />
                        </RouterLink>
                    )
                }
                {
                    clientLogoUrl && (
                        <img src={clientLogoUrl} alt={client.name} height={40} />
                    )
                }

                <Button
                    variant='text'
                    icon={isMobile ? <MenuIcon /> : <Person />}
                    iconOnly={isMobile}
                    color='primary'
                    size='small'
                    aria-controls={userMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={userMenuOpen ? 'true' : undefined}
                    onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}
                    ariaLabel='Menu'
                >
                    {isMobile ? '' : user.fullName}
                </Button>

                <Menu
                    anchorEl={userMenuAnchorEl}
                    open={userMenuOpen}
                    onClose={() => setUserMenuAnchorEl(null)}
                    sx={{maxHeight: '1000px'}}
                >
                    {
                        !user.isSystemsUser && client.accountNumber && (
                            <MenuItem onClick={copyAccountNumber}>
                                <ListItemIcon>
                                    <AssignmentInd />
                                </ListItemIcon>
                                <ListItemText disableTypography>
                                    <Typography variant='body2'>{client.accountNumber}</Typography>
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                    {
                        !user.isSystemsUser && client.accountNumber && (
                            <Divider />
                        )
                    }
                    {
                        menuItemToUse.filter(x => !x.disabled).map((x, index) => {
                            if (x.isDivider) {
                                return <Divider key={index} />
                            }

                            return (
                                <RouterLink to={x.value} key={index} onClick={() => setUserMenuAnchorEl(null)}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            {x.icon}
                                        </ListItemIcon>
                                        <ListItemText disableTypography>
                                            <Typography variant='body2'>{x.text}</Typography>
                                        </ListItemText>
                                    </MenuItem>
                                </RouterLink>
                            )
                        })
                    }
                </Menu>
            </Stack>
        </div>
    )
};

export default SiteHeader;