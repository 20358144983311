import { FunctionComponent, useMemo } from 'react';
import './metric-squares.scss';

import { Stack } from '@mui/material';

import { MetricsSquare } from '@luxon/components';
import { IObjectCountMetric } from '@luxon/interfaces';
import { TMetricBlockImage } from '../metrics-square';

interface ICalculatedMetrics {
    vehiclesCount: number;
    carsCount: number;
    bussesCount: number;
    trucksCount: number;
    motorbikesCount: number;
    bicyclesCount: number;
    peopleCount: number;
    totalCount: number;
}

interface IMetricBlock {
    count: number;
    label: string;
    imageName: TMetricBlockImage;
}

interface IMetricSquaresProps {
    metrics: IObjectCountMetric[];
    isLoading: boolean;
    size?: 'small' | 'normal';
    expandedMetrics?: boolean;
}
const MetricSquares: FunctionComponent<IMetricSquaresProps> = (props: IMetricSquaresProps) => {

    const { metrics, isLoading } = props;
    const calculatedMetrics = useMemo<ICalculatedMetrics>(() => {
        if (!metrics || metrics.length < 1) {
            return {
                totalCount: 0,
                vehiclesCount: 0,
                peopleCount: 0,
                bicyclesCount: 0,
                bussesCount: 0,
                carsCount: 0,
                motorbikesCount: 0,
                trucksCount: 0
            }
        }
        const newCalculatedMetrics: ICalculatedMetrics = {
            totalCount: metrics.map(x => x.totalCount).reduce((a, b) => a + b),
            vehiclesCount: metrics.map(x => x.totalVehicleCount).reduce((a, b) => a + b),
            peopleCount: metrics.map(x => x.personCount).reduce((a, b) => a + b),
            carsCount: metrics.map(x => x.carCount).reduce((a, b) => a + b),
            bussesCount: metrics.map(x => x.busCount).reduce((a, b) => a + b),
            trucksCount: metrics.map(x => x.truckCount).reduce((a, b) => a + b),
            motorbikesCount: metrics.map(x => x.motorbikeCount).reduce((a, b) => a + b),
            bicyclesCount: metrics.map(x => x.bicycleCount).reduce((a, b) => a + b)
        };
        newCalculatedMetrics.totalCount = newCalculatedMetrics.carsCount + newCalculatedMetrics.bussesCount + newCalculatedMetrics.trucksCount + newCalculatedMetrics.motorbikesCount + newCalculatedMetrics.peopleCount;
        return newCalculatedMetrics;
    }, [metrics]);

    const metricBlocks: IMetricBlock[] = [
        { label: 'Cars', imageName: 'car', count: calculatedMetrics?.carsCount },
        { label: 'Taxis', imageName: 'taxi', count: calculatedMetrics?.bussesCount },
        { label: 'Trucks', imageName: 'truck', count: calculatedMetrics?.trucksCount },
        { label: 'Motorbikes', imageName: 'motorbike', count: calculatedMetrics?.motorbikesCount },
        { label: 'Pedestrians', imageName: 'pedestrian', count: calculatedMetrics?.peopleCount }
    ];

    return (
        <Stack rowGap={3} columnGap={2} direction='row' className='metric-squares-wrapper'>
            {
                metricBlocks.map(x => (
                    <div key={x.label} className='square'>
                        <MetricsSquare
                            value={x.count}
                            description={x.label}
                            imageName={x.imageName}
                            isLoading={isLoading}
                            size='small' />
                    </div>
                ))
            }
            <div className='square main'>
                <MetricsSquare
                    value={calculatedMetrics?.totalCount}
                    description='Total'
                    isLoading={isLoading}
                    size={props.size} />
            </div>
        </Stack>
    );
};

export default MetricSquares;