import React, { FunctionComponent } from 'react';
import './user-action-page-frame.scss';

import { Card, CardContent, CardActions, Container, Unstable_Grid2 as Grid, Alert, Typography } from '@mui/material';

import { Button, Form, FormInputsOutlet, AlertGroup } from '@luxon/components';
import { IFormSubmitResult, IFormInput } from '@luxon/components/core/form/Form';
import { useScreenSize } from '@luxon/hooks';

import luxonLogoSmall from '@luxon/images/luxon-logo-small.png';

interface IUserActionPageFrameProps {
    formInputs: IFormInput[];
    formData?: any;
    onSubmit: (form: IFormSubmitResult) => void;
    errorMessages: React.ReactNode[];
    submitIcon: JSX.Element;
    submitText: string;
    isLoading: boolean;
    title: string;
    subTitle: string;
    body?: JSX.Element;
    footer?: JSX.Element;
    disabled?: boolean;
    pageErrorMessage?: string;
}
const UserActionPageFrame: FunctionComponent<IUserActionPageFrameProps> = (props: IUserActionPageFrameProps) => {

    const { currentSize, isMobile } = useScreenSize();

    return (
        <Form
            className='user-action-page-frame-container flex-center full-page'
            inputs={props.formInputs}
            formData={props.formData}
            onSubmit={props.onSubmit}
            disabled={props.disabled}
            noAutoComplete
        >
            <div className={`user-actions-page-frame-dark-section ${currentSize}`}></div>

            <div className='user-action-page-frame-card-wrapper'>
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            <img className={`center logo ${isMobile ? 'full' : 'small'}`} src={luxonLogoSmall} alt='Luxon Logo' />
                        </Grid>
                        <Grid xs={12} sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={4}>
                                        <Grid xs={12} display='flex' flexDirection='column' alignItems='center' rowGap={1}>
                                            <Typography variant='h4' textAlign='center'>{props.title}</Typography>
                                            <Typography variant='body1' textAlign='center'>{props.subTitle}</Typography>
                                        </Grid>

                                        {props.body}

                                        {
                                            props.pageErrorMessage ? (
                                                <Grid xs={12} spacing={2}>
                                                    <Alert severity='error'>{props.pageErrorMessage}</Alert>
                                                </Grid>
                                            ) : props.formInputs?.length > 0 ? (
                                                <Grid xs={12} spacing={2}>
                                                    <FormInputsOutlet />
                                                </Grid>
                                            ) : null
                                        }
                                    </Grid>
                                </CardContent>
                                {
                                    !props.pageErrorMessage && props.formInputs?.length > 0 && (
                                        <CardActions>
                                            <Grid container spacing={2} flex='1'>
                                                {
                                                    props.errorMessages?.length > 0 && (
                                                        <Grid xs={12}>
                                                            <AlertGroup messages={props.errorMessages} severity='error' />
                                                        </Grid>
                                                    )
                                                }
                                                <Grid xs={12} display='flex'>
                                                    <Button icon={props.submitIcon} type='submit' loading={props.isLoading} style={{flex: '1'}}>
                                                        {props.submitText}
                                                    </Button>
                                                </Grid>
                                                {
                                                    props.footer && (
                                                        <Grid xs={12} textAlign='center'>
                                                            {props.footer}
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </CardActions>
                                    )
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Form>
    )
};

export default UserActionPageFrame;