import React, { FunctionComponent, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import { LazyLoader, FeatureGuard } from '@luxon/components';
import ErrorPage from '@luxon/pages/error/ErrorPage';
import { FeatureFlag } from '@luxon/models';

const HomePage = lazy(() => import('./pages/home/HomePage'));
const HomeV2Page = lazy(() => import('./pages/home/HomeV2Page'));
const DemoAccessPage = lazy(() => import('./pages/demo-access/DemoAccessPage'));

const LoginPage = lazy(() => import('./pages/login/LoginPage'));
const TwoFactorAuthPage = lazy(() => import('./pages/two-factor-auth/TwoFactorAuthPage'));
const ResetPasswordPage = lazy(() => import('./pages/reset-password/ResetPasswordPage'));
const SignOutPage = lazy(() => import('./pages/sign-out/SignOutPage'));
const UserSetupPage = lazy(() => import('./pages/user-setup/UserSetupPage'));
const UsersPage = lazy(() => import('./pages/users/UsersPage'));
const UserPage = lazy(() => import('./pages/user/UserPage'));

const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));

const ClientsPage = lazy(() => import('./pages/clients/ClientsPage'));
const ClientPage = lazy(() => import('./pages/client/ClientPage'));
const ClientOnboardingPage = lazy(() => import('./pages/client-onboarding/ClientOnboardingPage'));

const AssetsPage = lazy(() => import('./pages/assets/AssetsPage'));
const AssetPage = lazy(() => import('./pages/asset/AssetPage'));
const AssetsMapPage = lazy(() => import('./pages/assets-map/AssetsMapPage'));
const AssetIPAllocationsPage = lazy(() => import('./pages/assets-ip-allocations/AssetIPAllocationsPage'));
const AssetsSnapshotsPage = lazy(() => import('./pages/assets-snapshots/AssetsSnapshotsPage'));
const AssetsPlannerPage = lazy(() => import('./pages/assets-planner/AssetsPlannerPage'));

const BillboardsPage = lazy(() => import('./pages/billboards/BillboardsPage'));
const BillboardPage = lazy(() => import('./pages/billboard/BillboardPage'));
const BillboardsMapPage = lazy(() => import('./pages/billboards-map/BillboardsMapPage'));

const ClientInvoicesPage = lazy(() => import('./pages/client-invoices/ClientInvoicesPage'));

const AnalyticsV2Page = lazy(() => import('./pages/analytics/AnalyticsV2Page'));
const BillboardAnalyticsPage = lazy(() => import('./pages/billboard-analytics/BillboardAnalyticsPage'));

const SupportTicketsPage = lazy(() => import('./pages/support-tickets/SupportTicketsPage'));
const SupportTicketPage = lazy(() => import('./pages/support-ticket/SupportTicketPage'));

const ClientCampaignsPage = lazy(() => import('./pages/client-campaigns/ClientCampaignsPage'));
const ClientCampaignPage = lazy(() => import('./pages/client-campaign/ClientCampaignPage'));

const QuotesPage = lazy(() => import('./pages/quotes/QuotesPage'));
const QuotePage = lazy(() => import('./pages/quote/QuotePage'));
const QuoteActionPage = lazy(() => import('./pages/quote-action/QuoteActionPage'));

const ClientInventoryRequestsPage = lazy(() => import('./pages/client-inventory-requests/ClientInventoryRequestsPage'));
const InventoryRequestSecureUploadPage = lazy(() => import('./pages/inventory-request-secure-upload/InventoryRequestSecureUploadPage'));

const XeroSetupPage = lazy(() => import('./pages/xero-setup/XeroSetupPage'));

const AppRouter: FunctionComponent = () => {

    const routes = createBrowserRouter([
        {
            element: (
                <LazyLoader title='Home'>
                    <FeatureGuard
                        fallbackComponent={<HomePage />}
                        featureName={FeatureFlag.HOME_PAGE_V2_ENABLED}
                    >
                        <HomeV2Page />
                    </FeatureGuard>
                </LazyLoader>
            ),
            index: true,
            errorElement: <ErrorPage />
        },
        {
            path: 'demo-access',
            element: (
                <LazyLoader title='Demo Access'>
                    <DemoAccessPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'login',
            element: (
                <LazyLoader title='Login'>
                    <LoginPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'dashboard',
            element: (
                <LazyLoader noTitle protected allowOnboardingClients allowSuspendedClients>
                    <DashboardPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />,
            children: [
//#region SYSTEM USERS
                {
                    path: 'clients',
                    element: (
                        <LazyLoader title='Clients' isAllowed={(user) => user.isSystemsUser}>
                            <ClientsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'clients/:clientId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <ClientPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'clients/:clientId/users',
                    element: (
                        <LazyLoader title='Users' isAllowed={(user) => user.isSystemsUser}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'clients/:clientId/users/:userId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'clients/:clientId/invoices',
                    element: (
                        <LazyLoader title='Client Invoices' isAllowed={(user) => user.isSystemsUser}>
                            <ClientInvoicesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'clients/:clientId/campaigns',
                    element: (
                        <LazyLoader title='Campaigns' isAllowed={(user) => user.isSystemsUser}>
                            <ClientCampaignsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'clients/:clientId/campaigns/:campaignId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <ClientCampaignPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'system-users',
                    element: (
                        <LazyLoader title='System Users' allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'system-users/:userId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'camera-assets',
                    element: (
                        <LazyLoader title='Assets' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'camera-assets/map',
                    element: (
                        <LazyLoader title='Assets Map' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsMapPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'camera-assets/ip-allocations',
                    element: (
                        <LazyLoader title='Asset IP Allocations' isAllowed={(user) => user.isSystemsUser}>
                            <AssetIPAllocationsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'camera-assets/snapshots',
                    element: (
                        <LazyLoader title='Asset Snapshots' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsSnapshotsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'camera-assets/planner',
                    element: (
                        <LazyLoader title='Asset Planner' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsPlannerPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'camera-assets/:assetId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <AssetPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'billboards',
                    element: (
                        <LazyLoader title='Billboards' isAllowed={(user) => user.isSystemsUser}>
                            <BillboardsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'billboards/map',
                    element: (
                        <LazyLoader title='Billboards Map' isAllowed={(user) => user.isSystemsUser}>
                            <BillboardsMapPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'billboards/:billboardId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <BillboardPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'quotes',
                    element: (
                        <LazyLoader title='Quotes' isAllowed={(user) => user.isSystemsUser}>
                            <QuotesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'quotes/:quoteId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <QuotePage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'client-inventory-requests',
                    element: (
                        <LazyLoader title='Client Inventory Requests' allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Sales']}>
                            <ClientInventoryRequestsPage />
                        </LazyLoader>
                    )
                },
//#endregion SYSTEM USERS


//#region CLIENT USERS
                {
                    path: 'onboarding',
                    element: (
                        <LazyLoader title='Onboarding' allowOnboardingClients isAllowed={(user) => !user.isSystemsUser}>
                            <ClientOnboardingPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'users',
                    element: (
                        <LazyLoader title='Users' allowedRoles={['ClientOwner', 'ClientAdmin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'users/:userId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['ClientOwner', 'ClientAdmin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'analytics',
                    element: (
                        <LazyLoader title='Inventory' isAllowed={(user) => !user.isSystemsUser}>
                            <AnalyticsV2Page />
                        </LazyLoader>
                    )
                },
                {
                    path: 'analytics/:billboardId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isSystemsUser}>
                            <BillboardAnalyticsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'account',
                    element: (
                        <LazyLoader noTitle allowedRoles={['ClientOwner', 'ClientAdmin', 'ClientViewOnly']} allowedSuspendedReasons={['NoPayment']}>
                            <ClientPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'invoices',
                    element: (
                        <LazyLoader title='Invoices' allowedRoles={['ClientOwner', 'ClientAdmin', 'ClientViewOnly']} allowedSuspendedReasons={['NoPayment']}>
                            <ClientInvoicesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'campaigns',
                    element: (
                        <LazyLoader title='Campaigns' isAllowed={(user) => !user.isSystemsUser}>
                            <ClientCampaignsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'campaigns/:campaignId',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isSystemsUser}>
                            <ClientCampaignPage />
                        </LazyLoader>
                    )
                },
//#endregion CLIENT USERS
                

//#region SHARED ROUTES
                {
                    path: 'profile',
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isAssumedSystemUser}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'support',
                    element: (
                        <LazyLoader
                            title='Support'
                            allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Support', 'ClientOwner', 'ClientAdmin', 'ClientViewOnly']}
                            allowedSuspendedReasons={['NoPayment']}
                        >
                            <SupportTicketsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'support/:supportTicketId',
                    element: (
                        <LazyLoader
                            noTitle
                            allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Support', 'ClientOwner', 'ClientAdmin', 'ClientViewOnly']}
                            allowedSuspendedReasons={['NoPayment']}
                        >
                            <SupportTicketPage />
                        </LazyLoader>
                    )
                }
//#endregion SHARED ROUTES
            ]
        },
        {
            path: 'sign-out/:userId',
            element: (
                <LazyLoader title='Sign Out'>
                    <SignOutPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'user-setup',
            element: (
                <LazyLoader title='Setup Account'>
                    <UserSetupPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '2fa/setup',
            element: (
                <LazyLoader title='Setup 2FA'>
                    <TwoFactorAuthPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '2fa/verify',
            element: (
                <LazyLoader title='Verify 2FA'>
                    <TwoFactorAuthPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'reset-password',
            element: (
                <LazyLoader title='Reset Password'>
                    <ResetPasswordPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'xero-setup',
            element: (
                <LazyLoader title='Link Xero Account' allowedRoles={['SystemsOwner']}>
                    <XeroSetupPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'xero-setup/auth-response',
            element: (
                <LazyLoader title='Xero Account Linked' allowedRoles={['SystemsOwner']}>
                    <XeroSetupPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: 'quotes/:quoteId',
            element: (
                <LazyLoader noTitle>
                    <QuoteActionPage />
                </LazyLoader>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '/inventory-request/secure-upload/:clientInventoryRequestId',
            element: (
                <LazyLoader title='Secure Document Upload'>
                    <InventoryRequestSecureUploadPage />
                </LazyLoader>
            )
        },
        {
            path: '*',
            element: (
                <Navigate to='/' replace />
            ),
            errorElement: <ErrorPage />
        }
    ]);

    return (
        <RouterProvider router={routes} />
    );
};

export default AppRouter;