import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { Autocomplete, GoogleMapsLibraryLoader } from '@luxon/components';
import { IFormInputBase } from '@luxon/components/core/form/Form';
import { IClientAddress, IMenuItem } from '@luxon/interfaces';

interface IAddressLookupProps extends IFormInputBase {
    addressSelected: (address: IClientAddress) => void;
}

const AddressLookup: FunctionComponent<IAddressLookupProps> = (props: IAddressLookupProps) => {
    const [googleInitialized, setGoogleInitialized] = useState(false);

    const [addressPredictions, setAddressPredictions] = useState<IMenuItem[]>([]);
    const [isSearchingPredictions, setIsSearchingPredictions] = useState(false);

    const googleAutocompleteApi = useRef<google.maps.places.AutocompleteService>();
    const googlePlacesApi = useRef<google.maps.places.PlacesService>();

    const extractStreet = (place: google.maps.places.PlaceResult): string => {
        if (!place.address_components || place.address_components.length < 1) {
            return null;
        }
    
        const streetNumberComponent = place.address_components.find(x => x.types.indexOf('street_number') > -1);
        const streetNameComponent = place.address_components.find(x => x.types.indexOf('route') > -1);
    
        const streetNumber = streetNumberComponent ? `${streetNumberComponent.long_name} ` : '';
        const streetName = streetNameComponent ? streetNameComponent.long_name : '';
    
        const street = `${streetNumber}${streetName}`;
        return street.trim() ? street.trim() : null;
    };

    const extractKeyInfo = (place: google.maps.places.PlaceResult, key: string, fuzzy?: boolean): string => {
        if (!place.address_components || place.address_components.length < 1) {
            return null;
        }
    
        let result = '';
        for (const component of place.address_components) {
            let hasKey = false;
            for (const type of component.types) {
                if (fuzzy && type.indexOf(key) > -1) {
                hasKey = true;
                break;
                } else if (!fuzzy && type === key) {
                hasKey = true;
                break;
                }
            }
            if (hasKey) {
                result = component.long_name;
                break;
            }
        }
    
        return result.trim() ? result.trim() : null;
    }

    const handlePredictionSelected = (placeId: string) => {
        if (!placeId) {
            setAddressPredictions([]);
            props.addressSelected(null);
            return;
        }
        
        setIsSearchingPredictions(true);
        googlePlacesApi.current.getDetails({
            placeId
        }, (response: google.maps.places.PlaceResult) => {
            setIsSearchingPredictions(false);
            props.addressSelected({
                addressLine1: extractStreet(response),
                addressLine2: extractKeyInfo(response, 'sublocality', true),
                addressLine3: '',
                addressLine4: '',
                city: extractKeyInfo(response, 'locality'),
                region: extractKeyInfo(response, 'administrative_area_level_1'),
                postalCode: extractKeyInfo(response, 'postal_code'),
                country: extractKeyInfo(response, 'country')
            });
        });
    }

    const searchAutocomplete = useCallback(() => {
        if (!props.value || props.value.length < 3) {
            return;
        }
        setIsSearchingPredictions(true);
        googleAutocompleteApi.current.getPlacePredictions({
            input: props.value,
            types: ['address'],
            componentRestrictions: {
                country: 'ZA'
            }
        }).then((response: google.maps.places.AutocompleteResponse) => {
            setIsSearchingPredictions(false);
            setAddressPredictions(response.predictions.map(x => ({
                text: x.description,
                value: x.place_id
            })));
        });
    }, [props.value]);

    useEffect(() => {
        searchAutocomplete();
    }, [props.value, searchAutocomplete]);

    useEffect(() => {
        if (!googleInitialized) {
            return;
        }

        if (!googleAutocompleteApi.current) {
            googleAutocompleteApi.current = new google.maps.places.AutocompleteService();
        }

        if (!googlePlacesApi.current) {
            googlePlacesApi.current = new google.maps.places.PlacesService(document.createElement('div'));
        }
    }, [googleInitialized]);

    return (
        <GoogleMapsLibraryLoader
            statusChanged={(status) => setGoogleInitialized(status === 'SUCCESS')}
        >
            <Autocomplete
                options={addressPredictions}
                inputTextValue={props.value}
                inputTextChanged={newVal => props.onChange ? props.onChange(newVal) : null}
                label={props.label}
                disabled={props.disabled}
                readOnly={props.readOnly}
                required={props.required}
                name={props.name}
                error={props.error}
                isSearching={isSearchingPredictions}
                noOptionsText='Cannot find your address? Enter manually below'
                onChange={handlePredictionSelected}
            />
        </GoogleMapsLibraryLoader>
    )
};

export default AddressLookup;