import React, { FunctionComponent } from 'react';

import { FormControl, InputLabel, Select as MuiSelect, MenuItem, InputAdornment } from '@mui/material';

import { IMenuItem } from '@luxon/interfaces';
import { IFormInputBase } from '../core/form/Form';

interface ISelectProps extends IFormInputBase {
    options: IMenuItem[];
    startIcon?: JSX.Element;
    size?: 'small' | 'medium';
    margin?: 'dense' | 'normal' | 'none';
    minWidth?: string;
    maxWidth?: string;
    maxHeight?: string | number;
}
const Select: FunctionComponent<ISelectProps> = (props: ISelectProps) => {

    const uniqueName = props.name ?? props.label;

    const getIcon = (icon: JSX.Element) => {
        const color = props.error ? 'error' : '';
        return React.cloneElement(icon, {
            color
        });
    };

    const buildStartIcon = () => !props.startIcon ? null : (
        <InputAdornment position='start'>
            {getIcon(props.startIcon)}
        </InputAdornment>
    );

    return (
        <FormControl
            fullWidth
            error={props.error}
            required={props.required}
            margin={props.margin}
            sx={{
                minWidth: props.minWidth,
                maxWidth: props.maxWidth
            }}>
            <InputLabel
                id={`select-${uniqueName}`}
                size={props.size === 'small' ? 'small' : 'normal'}
            >
                {props.label}
            </InputLabel>
            <MuiSelect
                labelId={`select-${uniqueName}`}
                value={props.value}
                label={props.label}
                onChange={(e) => props.onChange(e.target.value)}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                name={uniqueName}
                startAdornment={buildStartIcon()}
                size={props.size ?? 'medium'}
                readOnly={props.readOnly}
                MenuProps={{
                    sx: {
                        maxHeight: props.maxHeight
                    }
                }}
            >
                {
                    props.options.map(option => (
                        <MenuItem
                            value={option.value ?? option.text}
                            key={option.value ?? option.text}
                            disabled={option.disabled}
                        >
                            {option.text}
                        </MenuItem>
                    ))
                }
            </MuiSelect>
        </FormControl>
    )
};

export default Select;