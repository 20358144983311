import dayjs from 'dayjs';
import { environmentService } from '@luxon/services';
import { TMethod, IHttpResponse } from '@luxon/interfaces';

const ERROR_MESSAGE = 'Something went wrong. Please try again later.';

const POST = async <T>(url: string, body?: any, file?: File): Promise<IHttpResponse<T>> =>
    await execute('POST', url, null, body, file);

const PUT = async <T>(url: string, body?: any): Promise<IHttpResponse<T>> =>
    await execute('PUT', url, null, body);

const DELETE = async <T>(url: string, queryParams?: {[key: string]: any}): Promise<IHttpResponse<T>> => 
    await execute('DELETE', url, queryParams);

const GET = async <T>(url: string, queryParams?: {[key: string]: any}): Promise<IHttpResponse<T>> => 
    await execute('GET', url, queryParams);

const execute = async <T>(method: TMethod, url: string, queryParams?: {[key: string]: any}, body?: any, file?: File): Promise<IHttpResponse<T>> => {
    try {
        const fetchUrl = buildUrl(url, queryParams);
        const fetchOptions = buildFetchOptions(method, body, file);

        const fetchResponse = await fetch(fetchUrl, fetchOptions);
        if (fetchResponse.status === 401 || fetchResponse.status === 403) {
            window.location.href = '/sign-out/null';
            return null;
        }

        const responseHeaders = Object.fromEntries(fetchResponse.headers.entries());
        if (responseHeaders['content-disposition'] && responseHeaders['content-disposition'].indexOf('attachment') >= 0) {
            return await downloadResponseAsFile(fetchResponse);
        }

        const response = await fetchResponse.json();
        if (fetchResponse.ok) {
            massageResponse(response);
        }
        
        return {
            success: fetchResponse.ok,
            statusCode: fetchResponse.status,
            data: response,
            errorMessages: fetchResponse.ok ? null : extractErrorMessages(response),
            rawErrors: response.errors
        };
    } catch (err: any) {
        console.error(err);
        return {
            statusCode: 500,
            success: false,
            errorMessages: [ERROR_MESSAGE]
        }
    }
}

const downloadResponseAsFile = async <T>(response: Response): Promise<IHttpResponse<T>> => {
    const responseHeaders = Object.fromEntries(response.headers.entries());
    const contentDispositionParts = responseHeaders['content-disposition'].split(';');
    const fileNameParts = contentDispositionParts[contentDispositionParts.length - 1].split('='); 

    const blobResponse = await response.blob();
    const url = window.URL.createObjectURL(blobResponse);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileNameParts[fileNameParts.length - 1];
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();

    return {
        statusCode: 200,
        data: { message: 'File download will start shortly' } as T,
        errorMessages: [],
        success: true
    }
}

const isDate = (text: string): boolean =>
    text && typeof text === 'string' && text.indexOf('T') > -1 && !isNaN(parseInt(text.substring(0, 1))) && dayjs(text).isValid()

const massageResponse = (response: any): any => {
    if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
            response[i] = massageResponse(response[i]);
        }
    } else if (typeof response === 'object') {
        for (const key in response) {
            if (Array.isArray(response[key])) {
                for (let i = 0; i < response[key].length; i++) {
                    response[key][i] = massageResponse(response[key][i]);
                }
            } else if (typeof response[key] === 'object') {
                response[key] = massageResponse(response[key]);
            } else if (isDate(response[key])) {
                response[key] = dayjs(response[key]);
            }
        }
    } else if (isDate(response)) {
        return dayjs(response);
    }
    
    return response;
};

const buildUrl = (url: string, params?: {[key: string]: any}): string => {
    const queryString = buildQueryParams(params);
    return `${url.startsWith('http') ? '' : environmentService.getEnvironmentSettings().apiBaseUrl}${url}${queryString}`;
}

const buildQueryParams = (params: {[key: string]: any}): string => {
    if (!params) {
        return '';
    }

    const queryString = Object.keys(params)
        .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
        .map(key => {
            let paramString = params[key];
            if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
                paramString = JSON.stringify(params[key]); // This will generally be Date objects, so stringify will convert to ISO date

                if (dayjs.isDayjs(params[key])) { // Check if this is a dayjs object
                    paramString = params[key].toISOString();
                } else if (dayjs(params[key]).isValid()) { // Check if this is a Date objet
                    paramString = dayjs(params[key]).toISOString();
                }
            } else if (Array.isArray(params[key])) {
                const paramStringParts: string[] = [];
                for (const item of params[key]) {
                    paramStringParts.push(`${key}=${encodeURIComponent(item)}`);
                }
                return paramStringParts.join('&');
            }
            return `${key}=${encodeURIComponent(paramString)}`;
        })
        .join('&');

    return `?${queryString}`;
};

const buildFetchOptions = (method: TMethod, body?: any, file?: File): RequestInit => {
    const options: RequestInit = {
        method,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (file) {
        options.headers = {};
    }

    if (!file) {
        options.body = JSON.stringify(body);
    } else if (file) {
        const formData = new FormData();
        if (Array.isArray(file)) {
            for (const singleFile of file) {
                formData.append('file[]', singleFile);
            }
        } else {
            formData.append('file', file);
        }
        if (body) {
            const addFormEntry = (key: string, value: any) => {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        const formKey = `${key}[${i}]`;
                        addFormEntry(formKey, value[i]);
                    }
                } else if (typeof value === 'object') {
                    for (const valueKey in value) {
                        const formKey = `${key}.${valueKey}`;
                        const formValue = value[valueKey];
                        if (typeof formValue === 'object') {
                            addFormEntry(formKey, formValue);
                        } else {
                            formData.append(formKey, formValue);
                        }
                    }
                } else {
                    formData.append(key, value);
                }
            }

            for (const key in body) {
                addFormEntry(key, body[key]);
            }
        }
        options.body = formData;
    }
    return options;
};

const extractErrorMessages = (responseBody: any): string[] => {
    if (responseBody.errors && typeof responseBody.errors === 'object' && !Array.isArray(responseBody.errors) && Object.keys(responseBody.errors).length > 0) {
        const validErrors = Object.keys(responseBody.errors).filter(x => !x.startsWith('$'));
        if (validErrors.length > 0) {
            return validErrors.flatMap(key => responseBody.errors[key]);
        }
    } else if (responseBody.detail) {
        return [responseBody.detail];
    } else if (responseBody.message) {
        return [responseBody.message];
    }

    return [ERROR_MESSAGE];
};

const httpServiceWrapper = {
    buildQueryParams,
    buildUrl,
    execute,
    GET,
    POST,
    PUT,
    DELETE
};

export default httpServiceWrapper;