import { FunctionComponent } from "react";
import "./user-action-page-frame-v2.scss";
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  CardContent,
  Container,
  Unstable_Grid2 as Grid,
  Typography,
} from "@mui/material";

import { useScreenSize } from "@luxon/hooks";
import { classNames } from "@luxon/utils";

import luxonLogoSmall from "@luxon/images/luxon-logo-small.png";

interface IUserActionPageFrameV2Props {
  title: string;
  subTitle: string;
  children: any;
  logoLink?: string;
}
export const UserActionPageFrameV2: FunctionComponent<IUserActionPageFrameV2Props> = (props: IUserActionPageFrameV2Props) => {

  const {
    title,
    subTitle,
    children,
    logoLink
  } = props;

  const { currentSize, isMobile } = useScreenSize();

  const logoElement = (
    <img
      className={classNames(
        'logo',
        isMobile ? "full" : "small",
        'center'
      )}
      src={luxonLogoSmall}
      alt="Luxon Logo"
    />
  )

  return (
    <div className={classNames(
      'user-action-page-frame-v2',
      'flex-center',
      'full-page'
    )}>
      <div className={classNames('corner-animation', currentSize)}></div>

      <div className='card-wrapper'>
        <Container>
          <Grid container rowGap={4}>
            <Grid xs={12} sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
              {
                logoLink ? (
                  <RouterLink to={logoLink}>
                    {logoElement}
                  </RouterLink>
                ) : logoElement
              }
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
              <Card>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      rowGap={1}
                    >
                      <Typography variant="h4" textAlign="center">
                        {title}
                      </Typography>
                      <Typography variant="body1" textAlign="center">
                        {subTitle}
                      </Typography>
                    </Grid>

                    <Grid xs={12}>
                      {children}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
