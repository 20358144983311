const RESIZE_OBSERVER_DEFAULT_DEBOUNCE_TIME_MS = 100;

export const createResizeObserver = (callback: ResizeObserverCallback, debounceTimeMs: number = RESIZE_OBSERVER_DEFAULT_DEBOUNCE_TIME_MS) => {
  let debounceTimeout: any;
  return new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      debounceTimeout = null;
      
      callback(entries, observer);
    }, debounceTimeMs ?? RESIZE_OBSERVER_DEFAULT_DEBOUNCE_TIME_MS);
  });
}