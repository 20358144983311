import React, { FunctionComponent, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { SxProps, TextField, Theme } from '@mui/material';

import { useScreenSize } from '@luxon/hooks';
import { IFormInputBase } from '@luxon/components/core/form/Form';
import { DATE_FORMATS } from '@luxon/constants';

import { Dayjs } from 'dayjs';

interface IDatePickerProps extends IFormInputBase {
    dateFormat?: string;

    minDate?: Dayjs;
    maxDate?: Dayjs;
    margin?: 'none' | 'dense' | 'normal';
    sx?: SxProps<Theme>;
}
export const DatePicker: FunctionComponent<IDatePickerProps> = (props: IDatePickerProps) => {
    const { isMobile } = useScreenSize();
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const dateFormat = props.dateFormat ?? DATE_FORMATS.DayMonthYear;

    const getTextField = (params: any) => {

        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            if (props.readOnly || props.disabled) {
                return;
            }
            setIsPickerOpen(!isPickerOpen);
        }

        return (
            <TextField {...params}
                name={props.name}
                disabled={props.disabled}
                error={props.error}
                required={props.required}
                onClick={handleClick}
                inputProps={{
                    readOnly: true
                }}
                value={props.value ? props.value.format(dateFormat) : ''}
                margin={props.margin}
                sx={props.sx}
            />
        )
    }

    const getDatePicker = () => {
        if (isMobile) {
            return (
                <MobileDatePicker
                    onChange={props.onChange}
                    value={props.value}
                    inputFormat={dateFormat}
                    ignoreInvalidInputs={true}
                    label={props.label}
                    disabled={props.disabled}
                    renderInput={getTextField}
                    open={isPickerOpen}
                    onClose={() => setIsPickerOpen(false)}
                    disableMaskedInput={true}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    readOnly={props.readOnly}
                />
            )
        } else {
            return (
                <DesktopDatePicker
                    onChange={props.onChange}
                    value={props.value}
                    inputFormat={dateFormat}
                    label={props.label}
                    renderInput={getTextField}
                    open={isPickerOpen}
                    closeOnSelect={true}
                    onClose={() => setIsPickerOpen(false)}
                    disableMaskedInput={true}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    readOnly={props.readOnly}
                />
            )
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {getDatePicker()}
        </LocalizationProvider>
    )
};