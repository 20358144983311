import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { IReducerAction } from '@luxon/interfaces';

type TPageTitleActions = 'SET_TITLE';

interface IPageTitleContext {
    title?: string;
    setPageTitle?: (title: string) => void;
}

const initialState: IPageTitleContext = {
    title: ''
};
const PageTitleContext = React.createContext<IPageTitleContext>(initialState);

const PageTitleContextReducer = (state: IPageTitleContext, action: IReducerAction<TPageTitleActions>): IPageTitleContext => {
    switch (action.type) {
        case 'SET_TITLE':
            return {...state, title: action.payload };
        default:
            return state;
    }
};

const providerActions = {
    setPageTitle: (dispatcher: React.Dispatch<IReducerAction<TPageTitleActions>>, title: string) => {
        dispatcher({ type: 'SET_TITLE', payload: title });
    }
};

interface IPageTitleProviderProps {
    children: any;
};
const PageTitleContextProvider: FunctionComponent<IPageTitleProviderProps> = (props: IPageTitleProviderProps) => {
    const [state, dispatch] = useReducer(PageTitleContextReducer, {...initialState,
        setPageTitle: (title: string) => providerActions.setPageTitle(dispatch, title)
    });

    useEffect(() =>{
        if (!state.title) {
            return;
        }
        document.title = state.title;
    }, [state.title]);

    return (
        <PageTitleContext.Provider value={state}>
            {props.children}
        </PageTitleContext.Provider>
    );
}

export const usePageTitleContext = () => React.useContext(PageTitleContext);

export default PageTitleContextProvider;