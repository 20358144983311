import React, { FunctionComponent } from 'react';

import { Stack } from '@mui/material';

interface IIconTextProps {
    icon: React.ReactNode;
    iconSuffix?: React.ReactNode;
    children: React.ReactNode;
}
const IconText: FunctionComponent<IIconTextProps> = (props: IIconTextProps) => {

    return (
        <Stack display='flex' flexDirection='row' alignItems='center' columnGap={1}>
            {props.icon}
            {props.children}
            {props.iconSuffix}
        </Stack>
    )
};

export default IconText;