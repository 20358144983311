import React from 'react';

import useScreenSize from './screen-size';
import useThemePalette from './theme-palette';

import { AppSettingsContext, HttpClientContext, SnackbarContext, ConfirmContext } from '@luxon/contexts';

export {
    useScreenSize,
    useThemePalette
};

export * from './loading-manager';

export const useAppSettings = () => React.useContext(AppSettingsContext);
export const useHttpClient = () => React.useContext(HttpClientContext);
export const useSnackbar = () => React.useContext(SnackbarContext);
export const useConfirm = () => React.useContext(ConfirmContext);