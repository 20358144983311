import { FunctionComponent } from 'react';

import { BarChart as RechartsBarChart, Bar as RechartsBar } from 'recharts';

import { IGenericChartProps } from '.';

const BarChart: FunctionComponent<IGenericChartProps> = (props: IGenericChartProps) => {
    return (
        <RechartsBarChart
            data={props.data}
            width={props.width}
            height={props.height}
            className='chart'
            margin={{ left: -20 }}
        >
            {props.children}
            {
                props.dataKeys
                    .filter(x => props.disabledKeys.indexOf(x.dataKey) < 0)
                    .map(dataKey => (
                        <RechartsBar
                            key={dataKey.dataKey}
                            name={dataKey.legendName}
                            dataKey={dataKey.dataKey}
                            stroke={`var(--color-${dataKey.color})`}
                            fill={`rgba(var(--color-${dataKey.color}-rgb), 0.7)`}
                            stackId={dataKey.stackKey ?? dataKey.dataKey}
                            id={dataKey.dataKey}
                            animationDuration={500}
                            animationBegin={0}
                        />
                    ))
            }
        </RechartsBarChart>
    )
};

export default BarChart;