import React, { FunctionComponent } from 'react';

import Alert, { AlertColor } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

interface IAlertGroupProps {
    messages: React.ReactNode[];
    severity: AlertColor;
}
const AlertGroup: FunctionComponent<IAlertGroupProps> = (props: IAlertGroupProps) => {

    if (!props.messages || props.messages.length < 1) {
        return null;
    }

    return (
        <Alert severity={props.severity} data-testid='alert-group'>
            {
                props.messages.map((message, index) => (
                    <Typography
                        variant='body2'
                        key={index}
                        data-testid={`alert-group-message-${index}`}
                    >
                        {message}
                    </Typography>
                ))
            }
        </Alert>
    );
}

export default AlertGroup;