import { FunctionComponent } from 'react' ;

import { Stepper as MuiStepper, Step as MuiStep, StepLabel as MuiStepLabel, StepIconProps as MuiStepIconProps, Box } from '@mui/material';
import { IMenuItem } from '@luxon/interfaces';

interface IStepperProps {
    activeStepIndex: number;
    canNavigateBack?: boolean;
    activeStepChanged?: (newIndex: number) => void;
    steps: IMenuItem[];
}
const Stepper: FunctionComponent<IStepperProps> = (props: IStepperProps) => {

    const StepIcon = (step: IMenuItem, stepIndex: number, stepProps: MuiStepIconProps): JSX.Element => {
        return (
            <Box
                sx={{
                    height: 50,
                    width: 50,
                    color: 'var(--color-light)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    zIndex: 1,
                    cursor: props.canNavigateBack && stepProps.completed ? 'pointer' : 'default',
                    backgroundColor: stepProps.active || stepProps.completed ? 'var(--color-primary)' : 'var(--color-lightGrey)'
                }}
                onClick={() => {
                    if (props.canNavigateBack && stepProps.completed && props.activeStepChanged) {
                        props.activeStepChanged(stepIndex);
                    }
                }}
            >
                {step.icon}
            </Box>
        )
    };

    return (
        <MuiStepper alternativeLabel activeStep={props.activeStepIndex} sx={{marginBottom: '30px'}}>
            {
                props.steps.map((step, index) => (
                    <MuiStep key={step.text}>
                        <MuiStepLabel
                            StepIconComponent={(props) => StepIcon(step, index, props)}
                        >
                            {step.text}
                        </MuiStepLabel>
                    </MuiStep>
                ))
            }
        </MuiStepper>
    )
};

export default Stepper;