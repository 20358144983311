import { useState, useEffect, useCallback } from 'react';
import isEqual from 'fast-deep-equal/react';

interface ILoadingState<T> {
  isLoading: boolean;
  loadingProcess: keyof T | null;
  isProcessLoading: (key: keyof T) => boolean;
}
export function useLoadingManager<T>(loaders: Record<keyof T, boolean>): ILoadingState<T> {
  const [loadersState, setLoadersState] = useState<Record<keyof T, boolean>>();
  const [currentLoadingProcess, setCurrentLoadingProcess] = useState<keyof T | null>(null);
  const [isAnyProcessLoading, setIsAnyProcessLoading] = useState<boolean>(false);

  const isProcessLoading = useCallback((key: keyof T): boolean => {
    if (!loadersState) {
      return false;
    }
    return loadersState[key];
  }, [loadersState]);

  useEffect(() => {
    if (isEqual(loaders, loadersState)) {
      return;
    }

    const updatedLoaders = (loaders ?? {}) as Record<keyof T, boolean>;
    setLoadersState(updatedLoaders);

    const newLoadingProcess = (Object.keys(updatedLoaders).find((x) => loaders[x as keyof T]) as keyof T) ?? null;
    setCurrentLoadingProcess(newLoadingProcess);

    const isAnyLoading = Object.keys(updatedLoaders).filter((x) => loaders[x as keyof T]).length > 0;
    setIsAnyProcessLoading(isAnyLoading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaders]);

  return {
    isLoading: isAnyProcessLoading,
    isProcessLoading,
    loadingProcess: currentLoadingProcess
  }
}