import React, { FunctionComponent } from 'react';

import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { IAuditInfoBase } from '@luxon/interfaces';
import { useScreenSize } from '@luxon/hooks';

interface IAuditInfoProps {
    auditInfo: IAuditInfoBase;
    noPadding?: boolean;
}
const AuditInfo: FunctionComponent<IAuditInfoProps> = (props: IAuditInfoProps) => {
    const { isMobile } = useScreenSize();

    if (!props.auditInfo?.createdTimeStamp) {
        return null;
    }

    const dateFormat = 'H:mm, D MMM YY';

    const auditParts: Array<{ text: string, justifyContent: string }> = [
        {
            text: `Created: ${props.auditInfo.createdTimeStamp.format(dateFormat)}`,
            justifyContent: 'flex-start'
        }
    ];

    if (props.auditInfo.createdTimeStamp.isSame(props.auditInfo.auditTimeStamp)) {
        auditParts.push({ text: ' ', justifyContent: 'center' });
        auditParts.push({
            text: `Created By: ${props.auditInfo.auditUserFullName}`,
            justifyContent: 'flex-end'
        });
    } else {
        auditParts.push({
            text: `Changed: ${props.auditInfo.auditTimeStamp.format(dateFormat)}`,
            justifyContent: 'center'
        });
        auditParts.push({
            text: `Changed By: ${props.auditInfo.auditUserFullName}`,
            justifyContent: 'flex-end'
        });
    }

    return (
        <Grid container rowSpacing={0} flexDirection={isMobile ? 'column' : 'row'} paddingTop={props.noPadding ? 0 : null} paddingBottom={props.noPadding ? 0 : null}>
            {
                auditParts.map(x => (
                    <Grid
                        key={x.text}
                        xs={12}
                        sm={4}
                        display='flex'
                        justifyContent={isMobile ? 'flex-start' : x.justifyContent}
                    >
                        <Typography variant='caption' className='no-overflow' sx={{padding: '0 1px'}}>
                            {x.text}
                        </Typography>
                    </Grid>
                ))
            }
        </Grid>
    )
};

export default AuditInfo;