import React, { FunctionComponent, useState, useEffect } from 'react';

import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';

import { Button, Checkbox, Form, FormInputsOutlet } from '@luxon/components';
import { IFormInput, IFormSubmitResult } from '@luxon/components/core/form/Form';

interface IConfirmDialogProps {
    children?: any;
    open: boolean;
    title?: string;
    form?: IFormInput[];
    formInitData?: any;

    continueText?: string;
    cancelText?: string;

    acceptanceTexts?: string[];

    warningNotes?: string[]

    cancelClick?: (e: React.MouseEvent) => void;
    continueClick?: (e: React.MouseEvent) => void;
    formSubmitted?: (formData: IFormSubmitResult, e: React.FormEvent) => void;

    confirmOnly?: boolean;
}
const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props: IConfirmDialogProps) => {

    const [acceptedStates, setAcceptedStates] = useState<{[key: string]: boolean}>({});
    const [didAccept, setDidAccept] = useState(false);

    const handleAcceptanceTextCheckedChanged = (text: string, checked: boolean) => {
        const acceptedStatesCopy = Object.assign({}, acceptedStates);
        acceptedStatesCopy[text] = checked;
        setAcceptedStates(acceptedStatesCopy);
        setDidAccept(Object.keys(acceptedStatesCopy).filter(x => !acceptedStatesCopy[x]).length === 0);
    };

    const dialogBody = (dialogContent: JSX.Element) => (
        <>
            <DialogTitle>
                {props.title ?? 'Are you sure?'}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {dialogContent}
                    {
                        props.acceptanceTexts?.length > 0 && (
                            <Stack spacing={0}>
                                {
                                    props.acceptanceTexts.map(text => (
                                        <Checkbox size='small' key={text} label={text} value={acceptedStates[text]} onChange={(checked) => handleAcceptanceTextCheckedChanged(text, checked)} />
                                    ))
                                }
                            </Stack>
                        )
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                {
                    !props.confirmOnly && (
                        <Button variant='text' color='error' onClick={props.cancelClick}>
                            {props.cancelText ?? 'Cancel'}
                        </Button>
                    )
                }
                <Button
                    variant='text'
                    autoFocus
                    onClick={props.form?.length > 0 ? null : props.continueClick}
                    type={props.form?.length > 0 ? 'submit' : 'button'}
                    disabled={!didAccept && props.acceptanceTexts?.length > 0}
                >
                    {props.continueText ?? 'Continue'}
                </Button>
            </DialogActions>
        </>
    );

    const dialogText = (
        <DialogContentText>
            {props.children}
            {
                props.warningNotes?.length > 0 && (
                    <>
                        <br />
                        <br />
                       {
                            props.warningNotes.map(x => (
                                <Typography
                                    variant='body2'
                                    key={x}
                                    sx={{
                                        fontStyle: 'italic',
                                        fontWeight: 'bold',
                                        color: 'var(--color-warning)'
                                    }}
                                >
                                    NOTE: {x}
                                </Typography>
                            ))
                       } 
                    </>
                )
            }
        </DialogContentText>
    );

    const getDialogBodyWrapper = () => {
        if (!props.form || props.form.length < 1) {
            return dialogBody(dialogText);
        };

        const handleFormSubmit = (form: IFormSubmitResult, e: React.FormEvent) => {
            if (!form.valid || !props.formSubmitted || (!didAccept && props.acceptanceTexts?.length > 0)) {
                return;
            }

            props.formSubmitted(form, e);
        };

        return (
            <Form
                inputs={props.form}
                formData={props.formInitData}
                onSubmit={handleFormSubmit}
            >
                {dialogBody((
                    <Stack spacing={3}>
                        {dialogText}
                        <FormInputsOutlet />
                    </Stack>
                ))}
            </Form>
        )
    };

    useEffect(() => {
        setDidAccept(false);
    }, [props.form, props.open]);

    useEffect(() => {
        setAcceptedStates({});
        setDidAccept(false);
        if (!props.acceptanceTexts || props.acceptanceTexts.length < 1) {
            return;
        }

        const newAcceptedStates: {[key: string]: boolean} = {};
        for (const key of props.acceptanceTexts) {
            newAcceptedStates[key] = false;
        }
        setAcceptedStates(newAcceptedStates);
    }, [props.acceptanceTexts]);

    return (
        <Dialog open={props.open}>
            {getDialogBodyWrapper()}
        </Dialog>
    );
};

export default ConfirmDialog;