import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { IReducerAction } from '@luxon/interfaces';

import { IFormInput } from '@luxon/components';
import { isDayjs } from 'dayjs';
import { isDeeplyEqual, parseFormInputName, isFormNameAnArray } from '@luxon/formatters';

export type TFormMode = 'reactive' | 'independent';
export interface IFormChangedItem {
    value: any;
    name: string;
}
interface IFormContext {
    inputs: IFormInput[];
    formData: any;
    invalidInputs: string[];
    submitAttempted: boolean;
    disabled: boolean;
    readOnly: boolean;
    
    mode?: TFormMode;
    changedItem?: IFormChangedItem;

    reValidate?: () => void;
    inputChanged?: (changedItem: IFormChangedItem) => void;
}

const initialState: IFormContext = {
    inputs: [],
    formData: {},
    invalidInputs: [],
    submitAttempted: false,
    disabled: false,
    readOnly: false,
    mode: 'independent'
};
const FormContext = React.createContext<IFormContext>(initialState);

type TFormActions = 
    'SET_INPUTS' |
    'SET_FORM_DATA' |
    'SET_INVALID_INPUTS' |
    'SET_SUBMIT_ATTEMPTED' |
    'SET_DISABLED_STATE' |
    'SET_READONLY_STATE' |
    'INPUT_CHANGED' |
    'SET_MODE';

const FormContextReducer = (state: IFormContext, action: IReducerAction<TFormActions>): IFormContext => {
    const fixNestedPaths = (item: any) => {
        if (item && Array.isArray(item)) {
            for (const arrayItem of item) {
                fixNestedPaths(arrayItem);
            }
        } else if (item && typeof item === 'object') {
            for (const key in item) {
                if (item[key] && Array.isArray(item[key])) {
                    for (const arrayItem of item[key]) {
                        fixNestedPaths(arrayItem);
                    }
                } else if (typeof item[key] === 'object' && !isDayjs(item[key]) && item[key] !== null && item[key] !== undefined) {
                    fixNestedPaths(item[key]);
                } else if (key.indexOf('.') < 0) {
                    continue;
                } else {
                    let itemToChange = item;
                    const keyParts = key.split('.');
                    for (const keyPart of keyParts) {
                        const { itemName, itemIndex } = parseFormInputName(keyPart);

                        if (!itemName) {
                            continue;
                        }

                        if (isFormNameAnArray(keyPart)) {
                            if (itemIndex === null) {
                                itemToChange[itemName] = item[key];
                                continue;
                            }
    
                            if (!itemToChange[itemName]) {
                                itemToChange[itemName] = [{}];
                            }
                            
                            while (itemToChange[itemName].length <= itemIndex) {
                                itemToChange[itemName].push({});
                            }
                        } else if (!itemToChange[itemName]) {
                            itemToChange[itemName] = {};
                        }

                        if (keyParts.indexOf(keyPart) === keyParts.length - 1) {
                            itemToChange[itemName] = item[key];
                        } else if (!itemIndex && itemIndex !== 0) {
                            itemToChange = itemToChange[itemName];
                        } else {
                            itemToChange = itemToChange[itemName][itemIndex];
                        }
                    }

                    delete item[key];
                }
            }
        }

        return item;
    };

    switch (action.type) {
        case 'SET_INPUTS':
            return { ...state, inputs: action.payload };

        case 'SET_FORM_DATA':
            return { ...state, formData: action.payload };

        case 'SET_INVALID_INPUTS':
            return { ...state, invalidInputs: action.payload };

        case 'SET_SUBMIT_ATTEMPTED':
            return { ...state, submitAttempted: action.payload };

        case 'SET_DISABLED_STATE':
            return { ...state, disabled: action.payload };

        case 'SET_READONLY_STATE':
            return { ...state, readOnly: action.payload };

        case 'INPUT_CHANGED':
            const fdClone = Object.assign({}, state.formData ?? {});
            fdClone[action.payload.name] = action.payload.value;
            const newFormData = fixNestedPaths(fdClone)
            return { ...state, formData: newFormData };

        case 'SET_MODE':
            return { ...state, mode: action.payload }

        default:
            return state;
    }
};

interface IFormProviderProps {
    inputs?: IFormInput[];
    formData?: any;
    invalidInputs?: string[];
    submitAttempted?: boolean;
    children?: any;
    disabled?: boolean;
    readOnly?: boolean;
    mode?: TFormMode;
    formDataChanged?: (newFormData: any) => void
}
export const FormContextProvider: FunctionComponent<IFormProviderProps> = (props: IFormProviderProps) => {
    const [state, dispatch] = useReducer(FormContextReducer, {...initialState,
        formData: props.formData,
        inputChanged: (changedItem: IFormChangedItem) => dispatch({ type: 'INPUT_CHANGED', payload: changedItem })
    });

    useEffect(() => {
        dispatch({
            type: 'SET_INPUTS',
            payload: props.inputs
        });
    }, [ props.inputs ]);

    useEffect(() => {
        dispatch({
            type: 'SET_INVALID_INPUTS',
            payload: props.invalidInputs
        });
    }, [ props.invalidInputs ]);

    useEffect(() => {
        dispatch({
            type: 'SET_SUBMIT_ATTEMPTED',
            payload: props.submitAttempted
        });
    }, [ props.submitAttempted ]);

    useEffect(() => {
        dispatch({
            type: 'SET_DISABLED_STATE',
            payload: props.disabled
        });
    }, [ props.disabled ]);

    useEffect(() => {
        dispatch({
            type: 'SET_MODE',
            payload: props.mode
        });
    }, [ props.mode ]);

    useEffect(() => {
        dispatch({
            type: 'SET_READONLY_STATE',
            payload: props.readOnly
        });
    }, [ props.readOnly ]);

    useEffect(() => {
        if (isDeeplyEqual(state.formData, props.formData)) {
            return;
        }

        if (props.formDataChanged && state.formData) {
            props.formDataChanged(state.formData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.formData]);

    useEffect(() => {
        if (state.mode !== 'reactive') {
            return;
        }

        if (isDeeplyEqual(state.formData, props.formData)) {
            return;
        }

        dispatch({
            type: 'SET_FORM_DATA',
            payload: props.formData
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formData, state.mode]);

    return (
        <FormContext.Provider value={state}>
            {props.children}
        </FormContext.Provider>
    )
};

export const useFormContext = () => React.useContext(FormContext);