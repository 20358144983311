import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';

import { IReducerAction } from '@luxon/interfaces';
import { Button, Checkbox } from '@luxon/components';

type TSnackbarActions = 'OPEN' | 'CLOSE';

interface IConfirmConfig {
    title?: string;
    message: string;
    onPositive?: () => void | Promise<void>;
    onNegative?: () => void | Promise<void>;
    acceptanceTexts?: string[];
}

interface IConfirmContext {
    confirm: (config: IConfirmConfig) => void;
    isOpen: boolean;
    config: IConfirmConfig | null;
}

const initialState: IConfirmContext = {
    confirm: () => null,
    isOpen: false,
    config: null
};
export const ConfirmContext = React.createContext<IConfirmContext>(initialState);

const ConfirmContextReducer = (state: IConfirmContext, action: IReducerAction<TSnackbarActions>): IConfirmContext => {
    switch (action.type) {
        case 'OPEN':
            return {...state, isOpen: true, config: action.payload};
        case 'CLOSE':
            return {...state, isOpen: false, config: null};
        default:
            return state;
    }
};

interface IConfirmProviderProps {
    children: any;
};
export const ConfirmContextProvider: FunctionComponent<IConfirmProviderProps> = (props: IConfirmProviderProps) => {
    const [state, dispatch] = useReducer(ConfirmContextReducer, {...initialState,
        confirm: (config: IConfirmConfig) => {
            dispatch({
                type: 'OPEN',
                payload: config
            });
        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const [acceptedStates, setAcceptedStates] = useState<{[key: string]: boolean}>({});
    const didAccept = useMemo(() => {
        return Object.keys(acceptedStates).filter(x => !acceptedStates[x]).length === 0;
    }, [acceptedStates]);

    const handleAcceptanceTextCheckedChanged = (text: string, checked: boolean) => {
        const acceptedStatesCopy = Object.assign({}, acceptedStates);
        acceptedStatesCopy[text] = checked;
        setAcceptedStates(acceptedStatesCopy);
    };

    const handleNegativeClose = async () => {
        if (state.config?.onNegative) {
            await Promise.resolve(state.config.onNegative());
        }
        dispatch({ type: 'CLOSE' });
    }

    const handlePositive = async () => {
        if (state.config?.onPositive) {
            setIsLoading(true);
            await Promise.resolve(state.config.onPositive());
            setIsLoading(false);
        }
        dispatch({type: 'CLOSE'});
    }

    useEffect(() => {
        const acceptedStatesCopy: {[key: string]: boolean} = {};
        if (state.config?.acceptanceTexts?.length > 0) {
            for (const text of state.config.acceptanceTexts) {
                acceptedStatesCopy[text] = false;
            }
        }
        setAcceptedStates(acceptedStatesCopy);
    }, [state.config]);

    return (
        <ConfirmContext.Provider value={state}>
            {props.children}

            <Dialog
                open={state.isOpen}
                onClose={handleNegativeClose}
            >
                <DialogTitle>{state.config?.title ?? 'Are you sure?'}</DialogTitle>
                <DialogContent>
                    {state.config?.message}

                    {
                        state.config?.acceptanceTexts?.length > 0 && (
                            <Stack spacing={0}>
                                {
                                    state.config?.acceptanceTexts.map(text => (
                                        <Checkbox
                                            size='small'
                                            key={text}
                                            label={text}
                                            value={acceptedStates[text]}
                                            onChange={(checked) => handleAcceptanceTextCheckedChanged(text, checked)}
                                        />
                                    ))
                                }
                            </Stack>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='text'
                        color='error'
                        type='button'
                        onClick={handleNegativeClose}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='text'
                        type='button'
                        onClick={handlePositive}
                        loading={isLoading}
                        disabled={!didAccept}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </ConfirmContext.Provider>
    );
}