import { TMethod, TSortDirection } from "@luxon/interfaces";
import { FeatureFlag } from "./feature-flags";
import { QUERY_CLIENT_STALE_TIME } from '@luxon/constants';

export interface IAppSettings {
  apiBaseUrl: string;
  environment?: 'local' | 'dev' | 'prod';
  buildVersion?: string;
  googleApiKey?: string;
  xeroAppId?: string;
  featureFlags?: Partial<Record<FeatureFlag, boolean>>;
}

export interface IQuerySettings<TResponse = any, TInput = any> {
  enabled?: boolean;
  onSuccess?: (response: TResponse, input?: TInput) => void;
  onError?: (error: HttpClientError, input?: TInput) => void;
  staleTime?: number;
  refetchInterval?: number | false;
  keepPreviousData?: boolean;

  autoShowErrorMessages?: boolean;
  autoLogOutOnAuthError?: boolean;
}

export function getQuerySettings<TResponse = any, TInput = any>(settings?: IQuerySettings<TResponse, TInput>): IQuerySettings<TResponse, TInput> {
  return {
    enabled: true,
    staleTime: QUERY_CLIENT_STALE_TIME,
    refetchInterval: false,
    autoShowErrorMessages: true,
    autoLogOutOnAuthError: true,
    keepPreviousData: false,
    ...(settings ?? {})
  };
}

export interface ITableSorting<TDataType, TSortByType> {
  pageSize: number;
  pageIndex: number;
  sortBy: TSortByType;
  sortKey: keyof TDataType;
  sortDirection: TSortDirection;
}

interface IHttpErrorResponse {
  requestUrl: string;
  requestMethod: TMethod;
  statusCode: number;
  errorMessages: string[];
  rawErrors?: any;
}
export class HttpClientError extends Error {

  public statusCode: number;
  public firstErrorMessage: string;
  public errorMessages: string[];

  public errorResponse: IHttpErrorResponse;

  constructor(errorResponse: IHttpErrorResponse) {
      super(`[${errorResponse.statusCode}] error while performing [${errorResponse.requestMethod} ${errorResponse.requestUrl}]`, {
          cause: errorResponse.rawErrors
      });

      Object.setPrototypeOf(this, HttpClientError.prototype);

      this.errorResponse = errorResponse;
      this.statusCode = errorResponse.statusCode;
      this.firstErrorMessage = errorResponse.errorMessages[0];
      this.errorMessages = errorResponse.errorMessages;
  }
}