import { FunctionComponent, ReactNode, useRef, useEffect } from 'react';

import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails, AccordionActions as MuiAccordionActions, Typography, Unstable_Grid2 as Grid  } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

interface IAccordionProps {
    expanded: boolean;
    onToggle?: () => void;
    title: string;
    subTitle?: ReactNode;
    footer?: JSX.Element;
    children: any;
    hideExpandIcon?: boolean;
    doNotFocus?: boolean;
}
const Accordion: FunctionComponent<IAccordionProps> = (props: IAccordionProps) => {

    const accordionRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (!props.expanded || props.doNotFocus) {
            return;
        }

        const focusTimeout = setTimeout(() => {
            accordionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            });
        }, 250);

        return () => {
            clearTimeout(focusTimeout);
        }
    }, [props.expanded, props.doNotFocus]);

    return (
        <MuiAccordion
            expanded={props.expanded}
            ref={accordionRef}
        >
            <MuiAccordionSummary expandIcon={props.hideExpandIcon ? null : <ExpandMore onClick={props.onToggle} />}>
                <Grid container padding={0} xs={12} alignItems='center'>
                    <Grid xs={props.subTitle ? 8 : 12} onClick={props.onToggle} sx={{paddingLeft: 0}}>
                        <Typography variant='h5'>{props.title}</Typography>
                    </Grid>
                    {
                        props.subTitle && (
                            <Grid xs={4}>
                                {props.subTitle}
                            </Grid>
                        )
                    }
                </Grid>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
                {props.children}
            </MuiAccordionDetails>
            {
                props.footer && (
                    <MuiAccordionActions>
                        {props.footer}
                    </MuiAccordionActions>
                )
            }
        </MuiAccordion>
    )
}

export default Accordion;