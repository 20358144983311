export type TLocalStorageKeys = 'supportTicketFilters' | 'supportTicketSorting' | 'analyticsTableFilters';

export const setLocalStorageItem = (key: TLocalStorageKeys, value: any): void => {
    let storageValue: string = '';
    if (typeof value === 'object') {
        storageValue = JSON.stringify(value);
    } else {
        storageValue = value;
    }

    localStorage.setItem(key, storageValue);
}

export const getLocalStorageItem = <T>(key: TLocalStorageKeys): T => {
    const localItem = localStorage.getItem(key);
    if (!localItem) {
        return null;
    }

    return JSON.parse(localItem) as T;
}

export const removeLocalStorageItem = (key: TLocalStorageKeys): void => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = (): void => {
    localStorage.clear();
}