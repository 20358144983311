import React from 'react';

interface IFormV2Context<T> {
    formData: T;
    formErrors: Partial<Record<keyof T, boolean>>;
    disabled: boolean;
    handleFormChange: (name: keyof T, value: any) => void;
}
export const FormV2Context = React.createContext<IFormV2Context<any>>({
    formData: {},
    formErrors: {},
    disabled: false,
    handleFormChange: () => null
})


export function useFormV2Context<T>(): IFormV2Context<T> {
  return React.useContext(FormV2Context) as IFormV2Context<T>;
};