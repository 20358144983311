import React, { FunctionComponent } from 'react';

import { Chip, Tooltip } from '@mui/material';

import { TAssetMaintenanceMode, TAssetNetworkStatus, TClientCampaignStatus, TClientInvoiceStatus, TClientQuoteStatus, TColor, TStatus, TSupportTicketSeverity, TSupportTicketStatus, TSuspendedReason, TWarrantyStatus } from '@luxon/interfaces';
import { useUserContext } from '@luxon/providers';
import { splitText } from '@luxon/formatters';
import { DesignServices, Hardware, Speed } from '@mui/icons-material';

type TChipColor = 'default' | TColor;
type TChipStatus = TStatus | TWarrantyStatus | TClientInvoiceStatus | TSuspendedReason | TSupportTicketSeverity | TSupportTicketStatus | TAssetNetworkStatus | TClientCampaignStatus | TClientQuoteStatus | TAssetMaintenanceMode;
interface IStatusChipProps {
    status: TChipStatus | 'FileUploaded' | 'NoFileUploaded' | 'Unassigned' | 'ActiveCampaign' | 'Enabled' | 'Disabled' | 'TrialMode';
    subStatus?: TChipStatus;
    extraText?: string;
    size?: 'small' | 'medium';
    iconOnly?: boolean;
    tooltip?: string;
    onClick?: (event: React.MouseEvent) => void;
}
const StatusChip: FunctionComponent<IStatusChipProps> = (props: IStatusChipProps) => {
    const userContext = useUserContext();

    let chipText = props.status
        ? splitText(props.status)
        : '';

    if (chipText && props.subStatus) {
        chipText += ` - ${splitText(props.subStatus)}`;
    } else if (chipText && props.extraText) {
        chipText += ` - ${props.extraText}`;
    }

    let chipColor: TChipColor = 'default';
    let chipVariant: 'outlined' | 'filled' = 'filled';
    let chipIcon: JSX.Element = null;
    switch (props.status) {
        // TStatus
        case 'Suspended': chipColor = 'error'; break;
        case 'Active': chipColor = 'success'; break;
        case 'Onboarding': chipColor = 'default'; break;

        // TWarrantyStatus
        case 'InWarranty': chipColor = 'success'; break;
        case 'OutOfWarranty': chipColor = 'warning'; break;

        // TClientInvoiceStatus
        case 'AwaitingPayment': chipColor = 'error'; break;
        case 'PartialPayment': chipColor = 'warning'; break;
        case 'Paid': chipColor = 'success'; break;

        // TSuspendedReason
        // All just default to default color

        // TSupportTicketSeverity
        case 'Low': chipColor = 'default'; break;
        case 'Medium': chipColor = 'primary'; break;
        case 'High': chipColor = 'warning'; break;
        case 'Critical': chipColor = 'error'; break;

        // TSupportTicketStatus
        case 'WaitingAgentResponse':
            chipColor = userContext.user.isSystemsUser ? 'warning' : 'default';
            break;
        case 'WaitingClientResponse':
            chipColor = !userContext.user.isSystemsUser ? 'warning' : 'default';
            break;
        case 'Resolved': chipColor = 'success'; break;

        // TAssetNetworkStatus
        case 'Unknown':
            chipColor = 'default';
            break;
        case 'Up':
            chipColor = 'success';
            break;
        case 'Down':
            chipColor = 'error';
            break;
        case 'Degraded':
            chipColor = 'error';
            break;
        case 'LoadShedding':
            chipColor = 'warning';
            break;

        // TClientCampaignStatus
        case 'Running':
            chipColor = 'success';
            break;
        case 'NotStarted':
            chipColor = 'default';
            break;
        case 'Completed':
            chipColor = 'primary';
            break;
        case 'ActiveCampaign':
            chipColor = 'success';
            break;

        // TClientQuoteStatus
        case 'Sent':
            chipColor = 'default';
            break;
        case 'Accepted':
            chipColor = 'primary';
            break;
        case 'Rejected':
            chipColor = 'error';
            break;
        case 'Expired':
            chipColor = 'warning';
            break;
        case 'Invoiced':
            chipColor = 'success';
            break;

        // TAssetMaintenanceMode
        case 'StandardMaintenance':
            chipColor = 'primary';
            chipIcon = <Hardware fontSize='small' />
            break;
        case 'BenchTesting':
            chipColor = 'primary';
            chipIcon = <Speed fontSize='small' />
            break;
        case 'Planning':
            chipColor = 'primary';
            chipIcon = <DesignServices fontSize='small' />
            break;

        // CUSTOM
        case 'FileUploaded':
            chipColor = 'success';
            break;
        case 'NoFileUploaded':
            chipColor = 'error';
            break;
        case 'Unassigned':
            chipColor = 'error';
            break;
        case 'Enabled':
            chipColor = 'success';
            break;
        case 'Disabled':
            chipColor = 'error';
            break;
        case 'TrialMode':
            chipColor = 'warning';
            break;
    }

    if (!chipColor) {
        return null;
    }

    const chip = (
        <Chip
            size={props.size ?? 'small'}
            variant={chipVariant}
            label={props.iconOnly ? null : chipText}
            color={chipColor}
            icon={chipIcon}
            className={props.iconOnly ? 'iconOnly' : null}
            sx={{cursor: props.onClick ? 'pointer' : 'default'}}
            onClick={props.onClick} />
    );

    if (props.iconOnly || props.tooltip) {
        return (
            <Tooltip title={props.tooltip ?? chipText}>
                {chip}
            </Tooltip>
        )
    }

    return chip;
};

export default StatusChip;