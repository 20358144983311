import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

import { Autocomplete as MuiAutocomplete, TextField as MuiTextField, SxProps, Theme } from '@mui/material';
import { Clear } from '@mui/icons-material';

import { ProgressSpinner } from '@luxon/components';
import { IFormInputBase } from '../core/form/Form';
import { IMenuItem } from '@luxon/interfaces';

interface IAutocompleteProps extends IFormInputBase {
    options: IMenuItem[];
    isSearching: boolean;
    noOptionsText: string;
    inputTextChanged: (newVal: string) => void;
    inputTextValue?: string;
    size?: 'small' | 'medium';
    margin?: 'none' | 'dense' | 'normal';
    sx?: SxProps<Theme>;
}
const Autocomplete: FunctionComponent<IAutocompleteProps> = (props: IAutocompleteProps) => {

    const [textInputValue, setTextInputValue] = useState(props.value ?? props.inputTextValue ?? '');
    const debounceRef = useRef<NodeJS.Timeout>();

    const handleAutocompleteChanged = (e: React.SyntheticEvent, option: IMenuItem) => {
        props.onChange(option?.value ?? option?.text);
        setTextInputValue(option?.text);
    };

    const handleTextInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }
        setTextInputValue(e.target.value);
        debounceRef.current = setTimeout(() => {
            props.inputTextChanged(e.target.value)
        }, 200);
    }

    useEffect(() => {
        if (props.inputTextValue === undefined) {
            return;
        }
        setTextInputValue(props.inputTextValue);
    }, [props.inputTextValue]);

    return (
        <MuiAutocomplete
            options={props.options}
            isOptionEqualToValue={(option: IMenuItem, value: IMenuItem) => (option.value ?? option.text) === (value.value ?? value.text)}
            getOptionLabel={(option: IMenuItem) => option?.text ?? ''}
            size={props.size}
            loading={props.isSearching}
            noOptionsText={props.noOptionsText}
            autoHighlight
            inputValue={textInputValue}
            value={props.value ?? ''}
            onChange={handleAutocompleteChanged}
            clearIcon={<Clear />}
            disabled={props.disabled}
            sx={props.sx}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    value={textInputValue}
                    onChange={handleTextInputChanged}
                    label={props.label}
                    size={props.size}
                    disabled={props.disabled}
                    InputProps={{
                        ...params.InputProps,
                        readOnly: props.readOnly,
                        endAdornment: props.isSearching ? (
                            <ProgressSpinner size={20} />
                        ) : null
                    }}
                    margin={props.margin}
                    required={props.required}
                    name={props.name}
                    error={props.error}
                />
            )}
        />
    )
}

export default Autocomplete;