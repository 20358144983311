import React, { FunctionComponent, useReducer, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';

import { IReducerAction } from '@luxon/interfaces';
import { IAppSettings, QueryKeys, FeatureFlag } from '@luxon/models';
import { environmentService } from '@luxon/services';

type TAppSettingsContextActions = 'SET_APP_SETTINGS';

interface IAppSettingsContext {
    initialized: boolean;
    appSettings: IAppSettings;
    isFeatureEnabled: (flag: FeatureFlag) => boolean;
}

const initialAppSettingsState: IAppSettings = {
    apiBaseUrl: '',
    featureFlags: {}
};
const initialState: IAppSettingsContext = {
    initialized: false,
    appSettings: {...initialAppSettingsState},
    isFeatureEnabled: () => false
};
export const AppSettingsContext = React.createContext<IAppSettingsContext>(initialState);

const AppSettingsContextReducer = (state: IAppSettingsContext, action: IReducerAction<TAppSettingsContextActions>): IAppSettingsContext => {
    switch (action.type) {
        case 'SET_APP_SETTINGS':
            return { ...state, initialized: !!action.payload, appSettings: action.payload };
        default:
            return state;
    }
}

interface IAppSettingsContextProviderProps {
    children: any;
}
export const AppSettingsContextProvider: FunctionComponent<IAppSettingsContextProviderProps> = (props: IAppSettingsContextProviderProps) => {
    const [state, dispatch] = useReducer(AppSettingsContextReducer, {...initialState});

    const { data: appSettings = null } = useQuery<IAppSettings>([QueryKeys.AppSettings], async () => {
        const res = await fetch('/appsettings.json', {
            cache: 'no-store',
            method: 'GET'
        });
        return await res.json();
    }, {
        refetchInterval: 60000,
        keepPreviousData: true,
    });

    const isFeatureEnabled = useCallback((flag: FeatureFlag) => {
        return state.appSettings.featureFlags[flag];
    }, [state.appSettings]);

    useEffect(() => {
        dispatch({
            type: 'SET_APP_SETTINGS',
            payload: appSettings
        });

        if (appSettings) {
            console.debug(`Environment: ${appSettings.environment}, Version: ${appSettings.buildVersion}`);
            environmentService.setEnvironmentSettings({
                apiBaseUrl: appSettings.apiBaseUrl
            });
        }
    }, [appSettings]);

    if (!state.initialized) {
        return null;
    }

    return (
        <AppSettingsContext.Provider value={{...state, isFeatureEnabled}}>
            {props.children}
        </AppSettingsContext.Provider>
    )
};