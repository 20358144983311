import { FunctionComponent, useState } from 'react';

import { Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';

interface IImageLoaderProps {
  src: string;
  alt: string;
  loaderSx?: SxProps<Theme>
  imgClassName?: string;
  errorComponent?: JSX.Element;
  children?: any;
}
export const ImageLoader: FunctionComponent<IImageLoaderProps> = (props: IImageLoaderProps) => {

  const {
    src,
    alt,
    loaderSx,
    imgClassName,
    errorComponent,
    children
  } = props;

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isImageError, setIsImageError] = useState(false);
  
  if (isImageLoading || (isImageError && errorComponent)) {
    return (
      <Stack
        justifyContent='center'
        position='relative'
        alignItems='center'
        sx={loaderSx}
      >
        {
          isImageError ? errorComponent : children
        }

        <img
          src={src}
          alt={alt}
          height={0}
          width={0}
          onLoad={() => {
            setIsImageLoading(false);
            setIsImageError(false);
          }}
          onError={() => {
            setIsImageLoading(false);
            setIsImageError(true);
          }}
          style={{
            position: 'absolute',
            zIndex: -1,
            opacity: 0
          }}
        />
      </Stack>
    )
  }

  return (
    <img
      src={src}
      alt={alt}
      className={imgClassName}
      onError={() => setIsImageError(true)}
    />
  )
}