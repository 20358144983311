import React, { FunctionComponent, useEffect, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import PageLoaderPage from '@luxon/pages/page-loader/PageLoaderPage';
import { useClientContext, useUserContext, usePageTitleContext, PageTitleContextProvider } from '@luxon/providers';
import { IUser, TSuspendedReason, TUserRole } from '@luxon/interfaces';
import { httpService } from '@luxon/services';
import { FeatureFlag } from '@luxon/models';
import { useAppSettings } from '@luxon/hooks';

interface IProtectedRouteProps {
    allowedRoles?: TUserRole[];
    isAllowed?: (user: IUser) => boolean;
    allowOnboardingClients?: boolean;
    allowSuspendedClients?: boolean;
    allowedSuspendedReasons?: TSuspendedReason[];
    children: any;
}
const ProtectedRoute: FunctionComponent<IProtectedRouteProps> = (props: IProtectedRouteProps) => {
    const userContext = useUserContext();
    const clientContext = useClientContext();

    if (!userContext.initialized || !clientContext.initialized) {
        return null;
    }

    const goToSignOut = (queryParams?: {[key: string]: any}) => {
        const url = httpService.buildUrl(`/sign-out/${userContext.user?.id ?? null}`, queryParams)
        return <Navigate to={url} replace />;
    }
    
    if (
        !userContext.user?.id ||
        (props.allowedRoles?.length > 0 && props.allowedRoles.indexOf(userContext.user.role) < 0) ||
        (props.isAllowed && typeof props.isAllowed === 'function' && !props.isAllowed(userContext.user))
    ) {
        const redirectUri = `${window.location.pathname}${window.location.search}`;
        return goToSignOut({ r: redirectUri });
    } else if (clientContext.client.status === 'Onboarding' && !props.allowOnboardingClients) {
        return goToSignOut();
    } else if (clientContext.client.status === 'Suspended' && !props.allowSuspendedClients && (!props.allowedSuspendedReasons || !props.allowedSuspendedReasons.find(x => x === clientContext.client.suspendedReason))) {
        return goToSignOut();
    }

    return props.children;
};

interface ILazyLoaderProps extends IProtectedRouteProps {
    title?: string;
    noTitle?: boolean;

    protected?: boolean;
    allowedRoles?: TUserRole[];
    isAllowed?: (user: IUser) => boolean;
    allowOnboardingClients?: boolean;
    allowSuspendedClients?: boolean;
    allowedSuspendedReasons?: TSuspendedReason[];
    featureGate?: FeatureFlag;

    routeData?: any;

    children: any;
}

const TITLE_SUFFIX = 'Luxon';
const LazyLoaderPageWrapper: FunctionComponent<ILazyLoaderProps> = (props: ILazyLoaderProps) => {

    const pageTitleContext = usePageTitleContext();
    const { isFeatureEnabled } = useAppSettings();

    useEffect(() => {
        if (props.noTitle) {
            return;
        }

        let newPageTitle = TITLE_SUFFIX;
        if (props.title) {
            newPageTitle = `${props.title} | ${TITLE_SUFFIX}`;
        }

        if (newPageTitle !== pageTitleContext.title) {
            pageTitleContext.setPageTitle(newPageTitle);
        }
    }, [props.title, pageTitleContext, props.noTitle]);

    if (props.featureGate && !isFeatureEnabled(props.featureGate)) {
        return <Navigate to='/' replace />
    }

    if (props.protected || props.allowedRoles?.length > 0 || (props.isAllowed && typeof props.isAllowed === 'function')) {
        return (
            <ProtectedRoute
                allowedRoles={props.allowedRoles}
                isAllowed={props.isAllowed}
                allowOnboardingClients={props.allowOnboardingClients}
                allowedSuspendedReasons={props.allowedSuspendedReasons}
                allowSuspendedClients={props.allowSuspendedClients}
            >
                {props.children}
            </ProtectedRoute>
        )
    }

    return props.children;
};

const LazyLoader: FunctionComponent<ILazyLoaderProps> = (props: ILazyLoaderProps) => {

    return (
        <Suspense fallback={<PageLoaderPage />}>
            <PageTitleContextProvider>
                <LazyLoaderPageWrapper {...props} />
            </PageTitleContextProvider>
        </Suspense>
    )
};

export default LazyLoader;