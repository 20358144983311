export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const QUERY_CLIENT_STALE_TIME = 1000 * 60 * 5; // 5 Minutes

export const DATE_FORMATS = {
  /**
   * `D MMM YYYY HH:mm:ss`
   */
  FullDateTime: 'D MMM YYYY HH:mm:ss',

  /**
   * `D MMM YYYY`
   */
  DayMonthYear: 'D MMM YYYY',

  /**
   * `D MMM`
   */
  DayMonth: 'D MMM',

  /**
   * `D`
   */
  Day: 'D',

  /**
   * `ddd`
   */
  DayOfWeek: 'ddd',

  /**
   * `HH:mm`
   */
  HourMinute: 'HH:mm',

  /**
   * `D MMM HH:mm`
   */
  DayMonthHour: 'D MMM HH:mm'
};