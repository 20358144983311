import React, { FunctionComponent } from 'react';
import './moving-landing-page.scss';

import { useScreenSize } from '@luxon/hooks';

interface IMovingLandingPageProps {
    className?: string;
    children: any;
}
const MovingLandingPage: FunctionComponent<IMovingLandingPageProps> = (props: IMovingLandingPageProps) => {

    const { currentSize } = useScreenSize();

    const classNames = ['moving-landing-page'];
    if (props.className) {
        classNames.push(props.className);
    }

    return (
        <div className={classNames.join(' ')}>
            <div className={`dark-block ${currentSize}`}></div>

            {props.children}
        </div>
    );
};
export default MovingLandingPage;