import React, { FunctionComponent, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker as MuiTimePicker } from '@mui/x-date-pickers';
import { SxProps, TextField, Theme } from '@mui/material';

import { IFormInputBase } from '@luxon/components/core/form/Form';
import { DATE_FORMATS } from '@luxon/constants';

import { Dayjs } from 'dayjs';

interface ITimePickerProps extends IFormInputBase {
    timeFormat?: string;

    minTime?: Dayjs;
    maxTime?: Dayjs;
    margin?: 'none' | 'dense' | 'normal';
    sx?: SxProps<Theme>;
    views?: ('hours' | 'minutes' | 'seconds')[];
}
export const TimePicker: FunctionComponent<ITimePickerProps> = (props: ITimePickerProps) => {

    const {
        timeFormat,
        readOnly,
        disabled,
        name,
        error,
        required,
        value,
        margin,
        sx,
        onChange,
        label,
        minTime,
        maxTime,
        views
    } = props;

    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const dateFormat = timeFormat ?? DATE_FORMATS.HourMinute;

    const getTextField = (params: any) => {

        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            if (readOnly || disabled) {
                return;
            }
            setIsPickerOpen(!isPickerOpen);
        }

        return (
            <TextField {...params}
                name={name}
                disabled={disabled}
                error={error}
                required={required}
                onClick={handleClick}
                inputProps={{
                    readOnly: true
                }}
                value={value ? value.format(dateFormat) : ''}
                margin={margin}
                sx={sx}
            />
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiTimePicker
                onChange={onChange}
                value={value}
                inputFormat={dateFormat}
                label={label}
                disabled={disabled}
                renderInput={getTextField}
                open={isPickerOpen}
                closeOnSelect={true}
                onClose={() => setIsPickerOpen(false)}
                disableMaskedInput={true}
                minTime={minTime}
                maxTime={maxTime}
                readOnly={readOnly}
                views={views}
            />
        </LocalizationProvider>
    )
};