import { isDeeplyEqual, parseFormInputName } from '@luxon/formatters';
import React, { FunctionComponent, useState, useRef, ReactElement, useEffect, useCallback } from 'react';

import { FormContextProvider, TFormMode } from './form.provider';

export const CUSTOM_FORM_VALIDATORS = {
    StrongPassword: (val: string, formData: any): boolean => {
        if (!val) {
            return false;
        } else if (!val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+.=\-`])[A-Za-z\d~!@#$%^&*()_+.=\-`]{8,}$/g)) {
            return false;
        }
        return true;
    }
}

export interface IFormInputBase {
    name?: string;
    onChange?: (newValue: any, uniqueName?: string) => void;
    onClick?: (e: React.MouseEvent<HTMLElement>, uniqueName?: string) => void;
    value?: any;
    label?: string;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    error?: boolean;
}
export interface IFormInput extends IFormInputBase {
    controlType?: 'default' | 'nested' | 'button' | 'custom';
    gridWidth?: number | { xs?: number, sm?: number, md?: number, lg?: number, xl?: number };
    children?: IFormInput[];
    shouldRender?: boolean;
    component?: ReactElement<IFormInputBase>
    formData?: any;
    customValidator?: (val: any, formData: any) => boolean;
}
export interface IFormSubmitResult {
    getData: <T>() => T;
    valid: boolean;
    invalidItems: string[];
}


interface IFormProps {
    className?: string;
    inputs: IFormInput[];
    formData?: any;
    formDataChanged?: (formData: any) => void;
    onSubmit: (form: IFormSubmitResult, e: React.FormEvent) => void;
    children: any;
    disabled?: boolean;
    readOnly?: boolean;
    noAutoComplete?: boolean;
    mode?: TFormMode;
}
export const Form: FunctionComponent<IFormProps> = (props: IFormProps) => {
    const [invalidFormInputs, setInvalidFormInputs] = useState<string[]>([]);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [formData, setFormData] = useState<any>(props.formData);

    const formRef = useRef<HTMLFormElement>(null);

    const getInvalidEntries = useCallback((isSubmit: boolean): string[]  => {
        if (!formRef.current || (!submitAttempted && !isSubmit)) {
            return [];
        }

        const selfValidatedInvalidItems = Array.from(formRef.current.querySelectorAll(':invalid'))
            .map(x => x.getAttribute('name') ?? '');

        const formElements = Array.from(formRef.current.elements);
        const customValidatedInvalidItems = formElements
            .filter(x => x.hasAttribute('name'))
            .map(x => {
                const inputEl = x as HTMLInputElement;
                let matchingInput: IFormInput = props.inputs.find(x => x.name === inputEl.name);
                if (!matchingInput && inputEl.name.indexOf('.') && inputEl.name.indexOf('[') > -1) {
                    const nameParts = inputEl.name.split('.');
                    const { itemName } = parseFormInputName(nameParts[0]);
                    matchingInput = props.inputs.find(x => x.name === itemName).children.find(x => x.name === nameParts[1]);
                }

                if (inputEl.value && matchingInput && matchingInput.customValidator && !matchingInput.customValidator(inputEl.value, formData) && selfValidatedInvalidItems.indexOf(inputEl.name) < 0) {
                    return inputEl.name;
                } else if (matchingInput && !inputEl.value && matchingInput.required && selfValidatedInvalidItems.indexOf(inputEl.name) < 0) {
                    return inputEl.name;
                }

                return null;
            })
            .filter(x => x?.length > 0);

        const combinedInvalidItems = [...selfValidatedInvalidItems, ...customValidatedInvalidItems];

        const manuallyValidatedInvalidItems = props.inputs
            .filter(x => x.shouldRender !== false && !formElements.find(y => (y as HTMLInputElement).name === x.name))
            .filter(x => (x.required && !formData[x.name]) || (x.customValidator && !x.customValidator(formData[x.name], formData)))
            .filter(x => combinedInvalidItems.indexOf(x.name) < 0)
            .map(x => x.name);

        return [...combinedInvalidItems, ...manuallyValidatedInvalidItems];
    }, [props.inputs, formData, submitAttempted]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!submitAttempted) {
            setSubmitAttempted(true);
        }

        if (!formRef.current) {
            return;
        }

        const invalidEntries = getInvalidEntries(true);

        if (invalidEntries.length > 0) {
            setTimeout(() => {
                const inputEl = formRef.current.querySelector(`[name="${invalidEntries[0]}"]`) as HTMLElement;
                if (inputEl) {
                    inputEl.focus();
                }
            }, 200);
        }

        props.onSubmit({
            getData: () => formData as any,
            invalidItems: invalidEntries,
            valid: invalidEntries.length < 1
        }, e);
        setInvalidFormInputs(invalidEntries);
    }

    useEffect(() => {
        if (props.mode !== 'reactive' && props.formDataChanged) {
            props.formDataChanged(formData);
        }
        setTimeout(() => {
            setInvalidFormInputs(getInvalidEntries(false));
        }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, getInvalidEntries]);

    useEffect(() => {
        if (props.mode === 'reactive' && !isDeeplyEqual(formData, props.formData)) {
            setFormData(props.formData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formData, props.mode])
    
    return (
        <FormContextProvider
            inputs={props.inputs}
            formData={formData}
            invalidInputs={invalidFormInputs}
            submitAttempted={submitAttempted}
            disabled={props.disabled}
            readOnly={props.readOnly}
            formDataChanged={(fd) => {
                if (props.mode === 'reactive') {
                    props.formDataChanged(fd);
                } else {
                    setFormData(fd);
                }
            }}
            mode={props.mode ?? 'independent'}
        >
            <form
                className={props.className}
                ref={formRef}
                noValidate={true}
                onSubmit={handleSubmit}
                autoComplete={props.noAutoComplete ? 'off' : 'on'}
                autoCapitalize='on'
            >
                {props.children}
            </form>
        </FormContextProvider>
    )
};
