import React, { useCallback, useEffect, useRef, useState } from "react";

import isEqual from 'fast-deep-equal/react';

import { FormV2Context } from "./Form.context";

interface IFormV2Props<T> {
  formData: T;
  onSubmit: (formData: T) => void;
  children: any;
  onSubmitValidationError?: (firstInvalidItemName: keyof T) => void;
  formDataChanged?: (formData: T) => void;
  disabled?: boolean;
}
export function FormV2<T>(props: IFormV2Props<T>) {
  const { children, onSubmit, formData, disabled, formDataChanged, onSubmitValidationError } = props;

  const htmlFormRef = useRef<HTMLFormElement>(null);

  const [internalStagingForm, setInternalStagingForm] = useState<T>(formData);
  const [form, setForm] = useState<T>(formData);

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [formErrors, setFormErrors] = useState<Partial<Record<keyof T, boolean>>>({});

  const updateFormErrors = useCallback((forceCheck: boolean): Partial<Record<keyof T, boolean>> => {
      let newFormErrors: Partial<Record<keyof T, boolean>> = {};
      if ((submitAttempted || forceCheck) && htmlFormRef && htmlFormRef.current) {
        const invalidItems = Array.from(
          htmlFormRef.current.querySelectorAll(":invalid")
        ).map((x) => x.getAttribute("name") ?? "");

        invalidItems.forEach((x) => {
          newFormErrors[x as keyof T] = true;
        });
      }
      setFormErrors(newFormErrors);
      return newFormErrors;
  }, [submitAttempted]);

  const handleFormChange = (name: keyof T, value: any) => {
    setInternalStagingForm(fd => ({...fd, [name]: value}));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let latestFormErrors = updateFormErrors(true);
    if (!submitAttempted) {
      setSubmitAttempted(true);
    }

    if (Object.keys(latestFormErrors).length > 0) {
      setTimeout(() => {
        const inputEl = htmlFormRef.current?.querySelectorAll(`:invalid`)[0] as HTMLElement;
        if (inputEl && inputEl.focus) {
          inputEl.focus();
        }
      }, 200);
      if (onSubmitValidationError) {
        onSubmitValidationError(Object.keys(latestFormErrors)[0] as keyof T);
      }
      return;
    }

    onSubmit(form);
  };

  useEffect(() => {
    if (!isEqual(internalStagingForm, formData)) {
      formDataChanged(internalStagingForm);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalStagingForm]);

  useEffect(() => {
    setForm(formData);
    if (!isEqual(internalStagingForm, formData)) {
      setInternalStagingForm(formData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    updateFormErrors(false);
  }, [form, updateFormErrors]);

  return (
    <form
      className="luxon-form"
      onSubmit={handleSubmit}
      ref={htmlFormRef}
      noValidate={true}
      autoComplete="off"
      autoCapitalize="on"
      style={{
        width: "inherit",
      }}
    >
      <FormV2Context.Provider
        value={{
          formData: form,
          disabled: disabled ?? false,
          handleFormChange: handleFormChange as any,
          formErrors,
        }}
      >
        {children}
      </FormV2Context.Provider>
    </form>
  );
}
