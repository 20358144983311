import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { IFormInputBase } from '@luxon/components/core/form/Form';
import { TGeoMapMode } from '@luxon/components/geo-map';
import { GeoMap } from '@luxon/components';
import { IGeoPoint } from '@luxon/interfaces';
import { IGeoMarker } from '@luxon/components/geo-map/geo-marker';

interface IGeoMapFormWrapperProps extends IFormInputBase {
    mode: TGeoMapMode;
    mapCenter?: IGeoPoint;
    markers?: IGeoMarker[];
    polygons?: IGeoPoint[][];
    mapBoundsChanged?: (topRight: IGeoPoint, bottomLeft: IGeoPoint) => void;
}
const GeoMapFormWrapper: FunctionComponent<IGeoMapFormWrapperProps> = (props: IGeoMapFormWrapperProps) => {

    const handleMarkerPositionChanged = (newPoint: IGeoPoint) => {
        if (props.onChange) {
            props.onChange(newPoint);
        }
    };
    const handlePolygonPointsChanged = (points: IGeoPoint[]) => {
        if (props.onChange) {
            props.onChange(points);
        }
    };

    return (
        <Box sx={{marginTop: '25px'}}>
            <GeoMap
                mode={props.mode}
                editable={!props.disabled && !props.readOnly}
                markers={props.markers ?? []}
                markerAdded={(position) => handleMarkerPositionChanged(position)}
                markerPositionChanged={(marker, position) => handleMarkerPositionChanged(position)}
                polygonGeoPoints={props.mode === 'ASSET_FENCE' ? props.value : []}
                polygonGeoPointsChanged={handlePolygonPointsChanged}
                zoom={props.mode === 'ASSET' || props.mode === 'ASSET_FENCE' ? 16 : null}
                polygons={props.polygons}
                boundsChanged={props.mapBoundsChanged}
                center={props.mapCenter}
            />
        </Box>
    )
}

export default GeoMapFormWrapper;