import { useQuery, useMutation } from "react-query";

import { IDefaultResponseMessage, IExportSingleBillboardHourlyMetricsRequest, IExportSingleBillboardMetricsRequest, IExportSingleBillboardRawMetricsRequest, IGetMetricsRequest, ILDSv1Metric, IObjectCountMetric } from '@luxon/interfaces';
import { HttpClientError, getQuerySettings, IQuerySettings, QueryKeys } from '@luxon/models';
import { useHttpClient, useSnackbar } from '@luxon/hooks';

export enum MetricsSource {
  Billboard = 'billboard',
  LDSV1 = 'ldsv1'
}

export const useGetMetrics = (source: MetricsSource, request: IGetMetricsRequest, settings?: IQuerySettings<IObjectCountMetric[]>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IObjectCountMetric[], HttpClientError>([QueryKeys.Metrics, source, request], () => {
      return GET<IObjectCountMetric[]>(`/v1/metrics/${source}`, request);
  }, querySettings);
}

export const useGetLDSv1Metrics = (request: IGetMetricsRequest, settings?: IQuerySettings<ILDSv1Metric[]>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<ILDSv1Metric[], HttpClientError>([QueryKeys.Metrics, MetricsSource.LDSV1, request], () => {
      return GET<ILDSv1Metric[]>('/v1/metrics/ldsv1', request);
  }, querySettings);
}

interface ExportSingleBillboardMetrics {
  billboardId: string;
  request: IExportSingleBillboardMetricsRequest;
}
export const useExportSingleBillboardMetrics = (settings?: IQuerySettings<IDefaultResponseMessage, ExportSingleBillboardMetrics>) => {
  const { GET } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const exportSingleBillboardMetrics = ({ billboardId, request }: ExportSingleBillboardMetrics) => {
    return GET<IDefaultResponseMessage>(`/v1/metrics/billboard/${billboardId}/export`, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ExportSingleBillboardMetrics>(exportSingleBillboardMetrics, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
    onError: querySettings.onError
  });
}

interface ExportSingleBillboardHourlyMetrics {
  billboardId: string;
  request: IExportSingleBillboardHourlyMetricsRequest;
}
export const useExportSingleBillboardHourlyMetrics = (settings?: IQuerySettings<IDefaultResponseMessage, ExportSingleBillboardHourlyMetrics>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const exportSingleBillboardMetrics = ({ billboardId, request }: ExportSingleBillboardHourlyMetrics) => {
    return POST<IDefaultResponseMessage>(`/v1/metrics/billboard/${billboardId}/export-hourly`, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ExportSingleBillboardHourlyMetrics>(exportSingleBillboardMetrics, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
    onError: querySettings.onError
  });
}

interface ExportSingleBillboardRawMetrics {
  billboardId: string;
  request: IExportSingleBillboardRawMetricsRequest;
}
export const useExportSingleBillboardRawMetrics = (settings?: IQuerySettings<IDefaultResponseMessage, ExportSingleBillboardRawMetrics>) => {
  const { GET } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const exportSingleBillboardRawMetrics = ({ billboardId, request }: ExportSingleBillboardRawMetrics) => {
    return GET<IDefaultResponseMessage>(`/v1/metrics/billboard/${billboardId}/export-raw`, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ExportSingleBillboardRawMetrics>(exportSingleBillboardRawMetrics, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
    onError: querySettings.onError
  });
}