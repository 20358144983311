import React, { FunctionComponent } from 'react';
import './page-loader.scss';

import { ProgressSpinner } from '@luxon/components';

const PageLoaderPage: FunctionComponent = () => {
    return (
        <div className='page-loader-container absolute-full-page flex-center'>
            <ProgressSpinner color='secondary' />
        </div>
    )
};

export default PageLoaderPage;