import { useState, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


type TScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
interface IScreenSize {
    currentSize: TScreenSize;
    isMobile: boolean;
    isSmallDesktop: boolean;
    isLargeDesktop: boolean;
}
const useScreenSize = (): IScreenSize => {
    const [screenSize, setScreenSize] = useState<IScreenSize>({
        currentSize: 'xs',
        isMobile: true,
        isSmallDesktop: false,
        isLargeDesktop: false
    });

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));

    useEffect(() => {
        if (isXS && screenSize.currentSize !== 'xs') {
            setScreenSize({ currentSize: 'xs', isMobile: true, isSmallDesktop: false, isLargeDesktop: false });
        } else if (isSM && screenSize.currentSize !== 'sm') {
            setScreenSize({ currentSize: 'sm', isMobile: true, isSmallDesktop: false, isLargeDesktop: false });
        } else if (isMD && screenSize.currentSize !== 'md') {
            setScreenSize({ currentSize: 'md', isMobile: false, isSmallDesktop: true, isLargeDesktop: false });
        } else if (isLG && screenSize.currentSize !== 'lg') {
            setScreenSize({ currentSize: 'lg', isMobile: false, isSmallDesktop: true, isLargeDesktop: false });
        } else if (isXL && screenSize.currentSize !== 'xl') {
            setScreenSize({ currentSize: 'xl', isMobile: false, isSmallDesktop: false, isLargeDesktop: true });
        }
    }, [isXS, isSM, isMD, isLG, isXL, screenSize]);

    return screenSize;
};
export default useScreenSize;