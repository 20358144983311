import { FunctionComponent } from 'react';

import { formatNumber } from '@luxon/formatters';
import { TColor } from '@luxon/interfaces';

import { PieChart as RechartsPieChart, Pie as RechartsPie, Cell as RechartsCell } from 'recharts';

import { IGenericChartProps } from '.';

const PieChart: FunctionComponent<IGenericChartProps> = (props: IGenericChartProps) => {
    const cells: Array<{ label: string, value: any, color: TColor, key: string }> = props.dataKeys
        .filter(x => props.disabledKeys.indexOf(x.dataKey) < 0)
        .map(x => ({
            label: x.legendName,
            value: props.data.map(y => y[x.dataKey]).reduce((a, b) => a + b),
            color: x.color,
            key: x.dataKey
        }));

    return (
        <RechartsPieChart
            width={props.width}
            height={props.height}
            className='chart'
        >
            {props.children}
            <RechartsPie
                dataKey='value'
                data={cells}
                cx='50%'
                cy='50%'
                fill={props.chartColor ? `var(--color-${props.chartColor})` : null}
                label={data => formatNumber(data.value)}
                animationDuration={500}
                animationBegin={0}
            >
                {
                    cells.map(cell => (
                        <RechartsCell
                            key={cell.key}
                            fill={`var(--color-${cell.color})`}
                            stroke={props.chartColor ? `var(--color-${props.chartColor})` : null}
                        />
                    ))
                }
            </RechartsPie>
        </RechartsPieChart>
    )
};

export default PieChart;