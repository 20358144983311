import { DATE_FORMATS } from '@luxon/constants';
import { TBillboardSituatedSide } from "@luxon/interfaces";
import { Dayjs, isDayjs } from "dayjs";

const zarCurrencyFormatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR'
});
const zarCurrencyFormatterPrecise = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 6
});

const percentageFormatter = new Intl.NumberFormat('en-ZA', {
    style: 'percent',
    maximumFractionDigits: 6
});

const numberFormatter = new Intl.NumberFormat('en-ZA');

const formatCurrency = (amount: number, roundingType: 'STANDARD' | 'PRECISE' = 'STANDARD'): string => {
    if (roundingType === 'PRECISE') {
        return zarCurrencyFormatterPrecise.format(amount);
    }
    return zarCurrencyFormatter.format(amount);
}

const formatNumber = (amount: number): string => {
    return numberFormatter.format(amount);
}

const formatPercentage = (amount: number): string => {
    return percentageFormatter.format(amount / 100);
}

const roundNumber = (number: number, decimalPlaces?: number) => {
    const roundingFactor = Math.pow(10, decimalPlaces ?? 0);
    return Math.round(number * roundingFactor) / roundingFactor;
}

const nameOf = <T, K extends keyof T = keyof T>(name: K): K => name;

const parseFormInputName = (inputName: string): { itemName: string, itemIndex: number } => {
    const matches = new RegExp(/([a-zA-Z0-9-]{1,})(\[([0-9]{1,})\]){0,1}/, 'g').exec(inputName);
    const regexMatches = { ItemName: 1, ItemIndex: 3 };

    if (!matches || matches.length < 4) {
        return {
            itemIndex: null,
            itemName: null
        };
    }

    const itemIndex = parseInt(matches[regexMatches.ItemIndex], 10);

    return {
        itemName: matches[regexMatches.ItemName] ?? null,
        itemIndex: isNaN(itemIndex) ? null : itemIndex
    };
};

const isFormNameAnArray = (inputFullKey: string): boolean => {
    return new RegExp(/\[[0-9{1,}]\]/, 'g').test(inputFullKey);
};

const isDeeplyEqual = (originalItem: any, newItem: any): boolean => {
    if ((originalItem === null && newItem !== null) || (originalItem !== null && newItem === null)) {
        return false;
    } else if (originalItem === null && newItem === null) {
        return true;
    } else if (typeof newItem === 'object' && Array.isArray(newItem)) {
        if (originalItem.length !== newItem.length) {
            return false;
        }
        for (let i = 0; i < newItem.length; i++) {
            if (!originalItem[i] || !isDeeplyEqual(originalItem[i], newItem[i])) {
                return false;
            }
        }
    } else if (typeof newItem === 'object' && !isDayjs(newItem)) {
        if (typeof originalItem !== 'object' || isDayjs(originalItem)) {
            return false;
        }

        const newItemKeys = Object.keys(newItem).sort();
        const originalItemKeys = Object.keys(originalItem).sort();
        if (!isDeeplyEqual(originalItemKeys, newItemKeys)) {
            return false;
        }
        for (const key in newItem) {
            if (!isDeeplyEqual(originalItem[key], newItem[key])) {
                return false;
            }
        }
    } else if (typeof newItem === 'object' && isDayjs(newItem)) {
        if (typeof originalItem !== 'object' || !isDayjs(originalItem)) {
            return false;
        }

        return (newItem as Dayjs).isSame(originalItem);
    } else if (originalItem !== newItem) {
        return false;
    }

    return true;
};

const generateGuid = (): string => {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

const roundThousands = (value: number): string => {
    if (value < 1000) {
        return value.toString();
    } else if (value < 1000000) {
        return `${formatNumber(roundNumber(value / 1000, 1))}K`;
    } else if (value < 1000000000) {
        return `${formatNumber(roundNumber(value / 1000000, 2))}M`;
    }

    return `${formatNumber(roundNumber(value / 1000000000, 2))}M`;
}

const formatDateRange = (startDate: Dayjs, endDate: Dayjs): string => {
    if (!startDate || !endDate) {
        return null;
    }
    let startDateFormat = DATE_FORMATS.DayMonthYear;
    if (startDate.year() === endDate.year()) {
        startDateFormat = DATE_FORMATS.DayMonth;
        if (startDate.month() === endDate.month()) {
            startDateFormat = DATE_FORMATS.Day;
        }
    }

    return `${startDate.format(startDateFormat)} - ${endDate.format(DATE_FORMATS.DayMonthYear)}`;
}

const formatSituatedOn = (situatedSide: TBillboardSituatedSide): string => {
    switch (situatedSide) {
        case 'DriversLeftA': return 'A-Drivers Left';
        case 'DriversRightB': return 'B-Drivers Right';
        case 'GantryFaceA': return 'Gantry Face-A';
        case 'GantryFaceB': return 'Gantry Face-B';
        default: return '';
    }
}

/**
 * Splits concatenated strings by uppercase letters e.g. `SomeText` -> `Some Text`
 * @param text Text to split
 * @returns Split up text
 */
const splitText = (text: string) => text.match(/[A-Z][a-z]+/g)?.join(' ');

export {
    formatCurrency,
    formatNumber,
    formatPercentage,
    nameOf,
    parseFormInputName,
    isFormNameAnArray,
    isDeeplyEqual,
    generateGuid,
    roundNumber,
    roundThousands,
    formatDateRange,
    formatSituatedOn,
    splitText
}