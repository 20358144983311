import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Unstable_Grid2 as Grid, Typography, Stack, Skeleton, Alert, Container } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Button } from '@luxon/components';
import { useScreenSize } from '@luxon/hooks';
import { SxProps, Theme } from '@mui/system';

export const PageFrameSkeletonLoader: FunctionComponent = () => {
    return (
        <Stack spacing={3}>
            <Skeleton style={{height: '30px', width: '70%'}} />
            <Skeleton style={{height: '30px', width: '40%'}} />
            <Skeleton style={{height: '30px', width: '90%'}} />
        </Stack>
    )
};

interface IPageFrameProps {
    title?: React.ReactNode;
    enableBackNavigation?: boolean;
    className?: string;
    titleActions?: JSX.Element;
    initialized?: boolean;
    frameSize?: 'full' | 'responsive' | 'medium' | 'small';
    mainGridSX?: SxProps<Theme>;
    bodyGridSX?: SxProps<Theme>;
    isPageLoadError?: boolean;
    children: any;
}
const PageFrame: FunctionComponent<IPageFrameProps> = (props: IPageFrameProps) => {
    const navigate = useNavigate();
    
    const { isMobile } = useScreenSize();

    const [canNavigateBack, setCanNavigateBack] = useState(false);

    const pageTitleText = props.title ? (
        <Typography
            variant={isMobile ? 'h5' : 'h4'}
            flex='1'
        >
            {props.title}
        </Typography>
    ) : null;

    const pageTitle = props.enableBackNavigation && canNavigateBack ? (
        <Stack direction='row' spacing={3} alignItems='center' width={isMobile ? '100%' : 'auto'}>
            <Button iconOnly icon={<ArrowBack />} onClick={() => navigate(-1)} aria-label='Navigate Back' />
            {pageTitleText}
        </Stack>
    ) : pageTitleText;

    useEffect(() => {
        setCanNavigateBack(window.history.length > 1);
    }, []);

    return (
        <Container className={props.className} maxWidth={props.frameSize === 'responsive' ? 'lg' : props.frameSize === 'medium' ? 'md' : props.frameSize === 'small' ? 'sm' : false}>
            <Grid container rowSpacing={5} sx={props.mainGridSX}>
                <Grid
                    xs={12}
                    justifyContent='space-between'
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    display='flex'
                    rowGap={2}    
                    columnGap={2}    
                    flexWrap='wrap'
                >
                    {
                        props.initialized === false ? (
                            <Skeleton style={{height: '50px', width: '20%'}} />
                        ) : (
                            <>
                                {pageTitle}
                                {props.titleActions}
                            </>
                        )
                    }
                </Grid>

                <Grid xs={12} flexDirection='column' sx={props.bodyGridSX}>
                    {
                        props.initialized === false ? (
                            <PageFrameSkeletonLoader />
                        ) : props.isPageLoadError ? (
                            <Alert severity='error'>
                                Something went wrong. Please <span className='link' onClick={() => window.location.reload()}>refresh the page</span> or try again later.
                            </Alert>
                        ) : props.children
                    }
                </Grid>
            </Grid>
        </Container>
    )
};

export default PageFrame;