import { FunctionComponent } from 'react';

import { TUserRole, IUser } from '@luxon/interfaces';
import { useUserContext } from '@luxon/providers';

interface IRoleVisibilityProps {
    visibleRoles?: TUserRole[];
    notVisibleRoles?: TUserRole[];
    visible?: (user: IUser) => boolean;
    notVisible?: (user: IUser) => boolean;
    children: any;
}
const RoleVisibility: FunctionComponent<IRoleVisibilityProps> = (props: IRoleVisibilityProps) => {

    const userContext = useUserContext();

    if (props.visibleRoles?.length > 0 && props.visibleRoles.indexOf(userContext.user.role) < 0) {
        return null;
    } else if (props.notVisibleRoles?.length > 0 && props.notVisibleRoles.indexOf(userContext.user.role) >= 0) {
        return null;
    } else if (props.visible && typeof props.visible === 'function' && !props.visible(userContext.user)) {
        return null;
    } else if (props.notVisible && typeof props.notVisible === 'function' && props.notVisible(userContext.user)) {
        return null;
    }

    return props.children;
};

export default RoleVisibility;